import DefaultFormModel from "./defaultModel";
import { observable } from "mobx";

const RECIPIENT_DATA_STEP_INFO = {
  last_name: {
    required: true,
    default_error: "Фамилия не указана",
    value: "",
    placeholder: "Укажите фамилию",
  },
  first_name: {
    required: true,
    default_error: "Имя не указано",
    value: "",
    placeholder: "Укажите имя",
  },
  middle_name: {
    required: false,
    default_error: "",
    value: "",
    placeholder: "Укажите отчество",
  },
  birth_date: {
    required: true,
    default_error: "Дата рождения не указана",
    value: "",
    placeholder: "Выберите дату",
  },
  search_reason: {
    required: true,
    default_error: "Причина поиска не указана",
    value: "",
    placeholder: "Укажите причину поиска",
  },
  _image: {
    required: false,
    default_error: "",
    error_list: {
      "file-too-large":
        "Размер файла слишком большой, загрузите файл от 1кб до 8мб",
      "file-too-small":
        "Размер файла слишком маленький, загрузите файл от 1кб до 8мб",
      "custom-file-too-small":
        "Картинка слишком маленького расширения, минимальный размер: 200х200",
    },
    value: undefined,
  },
};

class RecipientDataStep extends DefaultFormModel<
  typeof RECIPIENT_DATA_STEP_INFO
> {
  @observable last_name: string;
  @observable first_name: string;
  @observable middle_name: string;
  @observable birth_date: string;
  @observable search_reason: string;
  @observable _image: File;

  constructor() {
    super(RECIPIENT_DATA_STEP_INFO);
    this.Instantiate(RECIPIENT_DATA_STEP_INFO);
  }
}

export default RecipientDataStep;
