import React from "react";
import { MobXProviderContext, useObserver } from "mobx-react";
import RootStore from "../stores/RootStore";

export function useStores() {
  return React.useContext(MobXProviderContext) as RootStore;
}

export function useStore(keys) {
  const stores = useStores();
  const observers = keys.reduce(
    (res, cur) => ((res[cur] = stores[cur]), res),
    {}
  );
  return useObserver(() => observers);
}
