import UiStore from "./UiStore";
import AuthStore from "./AuthStore";
import ResourceStore from "./ResourseStore";
import DonorCardStore from "./DonorCardStore";
import PictureStore from "./PictureStore";
import HonorStore from "./HonorStore";
import PlannedStore from "./PlannedStore";
import BloodStationStore from "./BloodStationStore";
import AdditionalPlacesStore from "./AdditionalPlacesStore";
import WidgetStore from "./WidgetStore";
import NewWidgetStore from "./NewWidgetStore";
import TriggerStore from "./TriggerStore";
import StoriesStore from "./StoriesStore";
import SelectedCityStore from "./SelectedCityStore";
import SpinnerStore from "./SpinnerStore";
import TokenStore from "./TokenStore";
import init_axios from "../utils/Axios";
import OauthStore from "./OauthStore";
import BonusStore from "./BonusStore";
import BannersStore from "./BannersStore";
import TopDonorsStore from "./TopDonorsStore";
import DonationFeedBackStore from "./DonationFeedBackStore";
import DonatorAdvicesStore from "./DonatorAdvicesStore";
import EventStore from "./EventStore";
import AddDonationStore from "./AddDonationStore";
import RecipientStore from "./RecipientStore";
import OrganizationStore from "./OrganizationStore";
import HelpMeStore from "./HelpMeStore";
import TopDonorPageStore from "./TopDonorPageStore";
import PlaceStore from "./PlaceStore";
import RandomActivities from "./RandomActivities";
import DonationsBeforeRegStore from "./DonationsBeforeRegStore";
import SurveyStore from "./SurveyStore";
import FundraisingStore from "./FundraisingStore";
import CoDonationsStore from "./CoDonationsStore";

class RootStore {
  uiStore: UiStore;
  authStore: AuthStore;
  donationStore: ResourceStore;
  citiesStore: ResourceStore;
  donorCardStore: DonorCardStore;
  bloodStationsStore: BloodStationStore;
  pictureStore: PictureStore;
  honorStore: HonorStore;
  newHonorStore: HonorStore;
  plannedStore: PlannedStore;
  additionalPlacesStore: AdditionalPlacesStore;
  widgetStore: WidgetStore;
  newWidgetStore: NewWidgetStore;
  donationsBeforeRegStore: DonationsBeforeRegStore;
  triggerStore: TriggerStore;
  storiesStore: StoriesStore;
  bloodStationsNeedStore: ResourceStore;
  interestingBlockStore: ResourceStore;
  waitingDonorNowStore: ResourceStore;
  mainPageStore: ResourceStore;
  selectedCityStore: SelectedCityStore;
  spinnerStore: SpinnerStore;
  tokenStore: TokenStore;
  oauthStore: OauthStore;
  bonusStore: BonusStore;
  topDonorsStore: TopDonorsStore;
  bannersStore: BannersStore;
  donationFeedBackStore: DonationFeedBackStore;
  donatorAdvicesStore: DonatorAdvicesStore;
  eventStore: EventStore;
  addDonationStore: AddDonationStore;
  recipientStore: RecipientStore;
  organizationStore: OrganizationStore;
  helpMeStore: HelpMeStore;
  topDonorPageStore: TopDonorPageStore;
  placeStore: PlaceStore;
  randomActivitiesStore: RandomActivities;
  surveyStore: SurveyStore;
  fundraisingStore: FundraisingStore;
  coDonationsStore: CoDonationsStore

  $axios: any;

  constructor(initialData) {
    this.tokenStore = new TokenStore(initialData?.tokenStore);
    this.$axios = init_axios(this.tokenStore);
    this.uiStore = new UiStore(initialData?.uiStore);
    this.placeStore = new PlaceStore("/api/", this.$axios);
    this.selectedCityStore = new SelectedCityStore(
      initialData?.selectedCityStore,
      this.$axios,
      "/api/cities/"
    );
    this.authStore = new AuthStore(
      this.$axios,
      initialData?.authStore,
      this.tokenStore
    );
    this.donationStore = new ResourceStore("/api/donations/", this.$axios);
    this.plannedStore = new PlannedStore("/api/donation_plan/", this.$axios);
    this.citiesStore = new ResourceStore("/api/cities/", this.$axios);
    this.bloodStationsStore = new BloodStationStore(
      "/api/blood_stations/",
      this.$axios
    );
    this.donorCardStore = new DonorCardStore(
      "/api/auth/donor_card/",
      this.$axios
    );
    this.pictureStore = new PictureStore("/api/picture/", this.$axios);
    // ! удалить после выкатки нового лк
    this.honorStore = new HonorStore("/api/donations/honor/", this.$axios);
    this.newHonorStore = new HonorStore("/api/profile/honor/", this.$axios);
    this.additionalPlacesStore = new AdditionalPlacesStore(
      "/api/blood_stations/selected/",
      this.$axios
    );
    this.widgetStore = new WidgetStore("/api/donations/widget/", this.$axios);
    this.newWidgetStore = new NewWidgetStore(
      "/api/profile/planned-donation/",
      this.$axios
    );
    this.donationsBeforeRegStore = new DonationsBeforeRegStore(
      "/api/auth/donor_card/",
      this.$axios
    );
    this.triggerStore = new TriggerStore("api/profile/trigger/", this.$axios);
    this.storiesStore = new StoriesStore("/api/stories/", this.$axios);
    this.bloodStationsNeedStore = new ResourceStore("/api/needs/", this.$axios);
    this.interestingBlockStore = new ResourceStore(
      "/api/interesting/",
      this.$axios
    );
    this.waitingDonorNowStore = new ResourceStore(
      "/api/waiting_donor/",
      this.$axios
    );
    this.mainPageStore = new ResourceStore("/api/users_count/", this.$axios);
    this.spinnerStore = new SpinnerStore();
    this.bonusStore = new BonusStore("/api/bonuses/", this.$axios);
    this.topDonorsStore = new TopDonorsStore(
      "/api/users_top_main_page/",
      this.$axios
    );
    this.bannersStore = new BannersStore("/api/banners/", this.$axios);
    this.oauthStore = new OauthStore(this.$axios);
    this.donationFeedBackStore = new DonationFeedBackStore(
      "/api/donation_feedback/",
      this.$axios
    );
    this.donatorAdvicesStore = new DonatorAdvicesStore(
      "/api/advices/",
      this.$axios
    );
    this.eventStore = new EventStore("/api/events/", this.$axios);
    this.recipientStore = new RecipientStore(
      "/api/address_needs/",
      this.$axios,
      this.selectedCityStore
    );
    this.addDonationStore = new AddDonationStore();
    this.organizationStore = new OrganizationStore(
      "/api/organizations/",
      this.$axios
    );
    this.helpMeStore = new HelpMeStore(
      "/api/address_needs/",
      this.$axios,
      this.citiesStore,
      this.bloodStationsStore
    );
    this.topDonorPageStore = new TopDonorPageStore(
      "/api/users_top/",
      this.$axios,
      this.placeStore
    );
    this.randomActivitiesStore = new RandomActivities(
      "/api/profile/activity/",
      this.$axios
    );
    this.fundraisingStore = new FundraisingStore(
      "/api/collects/",
      this.$axios
    )
    this.surveyStore= new SurveyStore("/api/", this.$axios);
    this.coDonationsStore = new CoDonationsStore("/api/collective_donations", this.$axios);
  }
}

export default RootStore;
