import { action, computed, observable, toJS } from "mobx";
import { AxiosInstance } from "axios";
import redirectTo from "../utils/redirectTo";
import {
  TActionAuthor,
  TEventTimeSlot,
  TUser,
  TUserStatus,
} from "./EventStore";
import { BloodClass, BloodGroup } from "../entities";
import { IBloodStation } from "../components/profileThankYouPage/profileThankYouPage";
import moment from "moment";
import SelectedCityStore from "./SelectedCityStore";

const sortFunc = (as: TEventTimeSlot, bs: TEventTimeSlot) => {
  const a = moment(as.date).toDate();
  const b = moment(bs.date).toDate();
  if (new Date(a) > new Date(b)) {
    return 1;
  }
  if (new Date(a) < new Date(b)) {
    return -1;
  }
  return 0;
};

export type TRecipientData = {
  id: number;
  author: TActionAuthor;
  total_joined_count: number;
  image: string;
  blood_station: IBloodStation;
  time_slots: TRecipientTimeSlot[];
  joined_users: TUser[];
  object_id: number;
  blood_class: BloodClass;
  last_date: string;
  required_donors_count: number;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  _image: string;
  cropping: string;
  birth_date: string;
  search_reason: string;
  status: TRecipientStatus;
  content_type: number;
  o_plus: TBloodNeed;
  o_minus: TBloodNeed;
  a_plus: TBloodNeed;
  a_minus: TBloodNeed;
  b_plus: TBloodNeed;
  b_minus: TBloodNeed;
  ab_plus: TBloodNeed;
  ab_minus: TBloodNeed;
  user_status: TUserStatus;
};

export type TBloodNeed = "need" | "no_need";

export type TRecipientTimeSlot = {
  id: number;
  date: string;
  joined_count: number;
};

export type TRecipientBloodNeed = {
  name: BloodGroup;
  value: TBloodNeed;
};

type TRecipientStatus =
  | "search_in_progress"
  | "donors_found"
  | "rejected"
  | "search_completed";

class RecipientStore {
  @observable _url: string;
  @observable _client: AxiosInstance;
  @observable private _loading: boolean;
  @observable private _isJoinedUsersModalOpen = false;
  @observable private _selectedCityStore: SelectedCityStore;

  constructor(
    url: string,
    client: AxiosInstance,
    selectedCityStore: SelectedCityStore
  ) {
    this._url = url;
    this._client = client;
    this._selectedCityStore = selectedCityStore;
  }

  @observable private _bloodStationRecipientNeeds: TRecipientData[];

  @computed get bloodStationRecipientNeeds() {
    return toJS(this._bloodStationRecipientNeeds);
  }

  @observable _recipientData: TRecipientData;

  @computed get recipientData() {
    return toJS(this._recipientData);
  }

  @observable private _recipientID: number | string;

  @computed get recipientID() {
    return toJS(this._recipientID);
  }

  @observable private _selectedTimeSlot: TRecipientTimeSlot;

  @computed get selectedTimeSlot() {
    return toJS(this._selectedTimeSlot);
  }

  @computed get isLoading() {
    return toJS(this._loading);
  }

  @computed get status() {
    return toJS(this.recipientData?.status);
  }

  @computed get isActive() {
    return toJS(this.recipientData?.status === "search_in_progress");
  }

  @computed get recipientUserStatus() {
    return toJS(this.recipientData?.user_status);
  }

  @computed get isModalOpen() {
    return toJS(this._isJoinedUsersModalOpen);
  }

  @computed get validTimeSlots() {
    return toJS(
      this.recipientData?.time_slots?.filter((ts) => {
        return new Date(new Date(ts.date).setHours(23, 59, 59)) >= new Date();
      })
    )?.sort(sortFunc);
  }

  @computed get timeSlotsLength() {
    return toJS(this.validTimeSlots)?.length || 0;
  }

  @computed get recipientAsker() {
    return toJS({
      first_name: this.recipientData?.first_name,
      middle_name: this.recipientData?.middle_name,
      last_name: this.recipientData?.last_name,
      image: this.recipientData?.image,
      birth_date: this.recipientData?.birth_date,
      search_reason: this.recipientData?.search_reason,
    });
  }

  @computed get recipientBloodTypeNeeds(): TRecipientBloodNeed[] {
    return [
      { name: "o_plus" as BloodGroup, value: this.recipientData?.o_plus },
      { name: "o_minus" as BloodGroup, value: this.recipientData?.o_minus },
      { name: "a_plus" as BloodGroup, value: this.recipientData?.a_plus },
      { name: "a_minus" as BloodGroup, value: this.recipientData?.a_minus },
      { name: "b_plus" as BloodGroup, value: this.recipientData?.b_plus },
      { name: "b_minus" as BloodGroup, value: this.recipientData?.b_minus },
      { name: "ab_plus" as BloodGroup, value: this.recipientData?.ab_plus },
      { name: "ab_minus" as BloodGroup, value: this.recipientData?.ab_minus },
    ].filter((item) => item.value === "need");
  }

  @computed get recipientBloodNeeds() {
    return toJS({
      blood_class: this.recipientData?.blood_class,
      needs: {
        o_plus: this.recipientData?.o_plus,
        o_minus: this.recipientData?.o_minus,
        a_plus: this.recipientData?.a_plus,
        a_minus: this.recipientData?.a_minus,
        b_plus: this.recipientData?.b_plus,
        b_minus: this.recipientData?.b_minus,
        ab_plus: this.recipientData?.ab_plus,
        ab_minus: this.recipientData?.ab_minus,
      },
      actual_needs: this.recipientBloodTypeNeeds,
    });
  }

  @observable _mainLoading = true;

  @computed get mainLoading() {
    return toJS(this._mainLoading);
  }

  @action getRecipientBloodNeedsByRecipientId = (data: TRecipientData) => {
    return [
      { name: "o_plus" as BloodGroup, value: data?.o_plus },
      { name: "o_minus" as BloodGroup, value: data?.o_minus },
      { name: "a_plus" as BloodGroup, value: data?.a_plus },
      { name: "a_minus" as BloodGroup, value: data?.a_minus },
      { name: "b_plus" as BloodGroup, value: data?.b_plus },
      { name: "b_minus" as BloodGroup, value: data?.b_minus },
      { name: "ab_plus" as BloodGroup, value: data?.ab_plus },
      { name: "ab_minus" as BloodGroup, value: data?.ab_minus },
    ].filter((item) => item.value === "need");
  };

  @action setIsModalOpen = (v: boolean) => (this._isJoinedUsersModalOpen = v);

  @action setRecipientID = (v: number | string) => (this._recipientID = v);

  @action setRecipientData = (v: TRecipientData) => (this._recipientData = v);

  @action setLoading = (v: boolean) => (this._loading = v);

  @action setSelectedTimeSlot = (v: TRecipientTimeSlot) =>
    (this._selectedTimeSlot = v);

  @action setBloodStationRecipientNeeds = (v: TRecipientData[]) =>
    (this._bloodStationRecipientNeeds = v);

  @action loadRecipientData = (dynamic = false) => {
    if (!dynamic) this.setLoading(true);
    return this._client
      .get(`${this._url}${this.recipientID}/`)
      .then((res) => {
        this.setRecipientData(res.data as TRecipientData);
        if (this.recipientData?.user_status?.joined) {
          if (this.recipientData?.user_status.joined.timeslot) {
            this.setSelectedTimeSlot(
              this.recipientData?.user_status.joined.timeslot
            );
          }
        }
        if (this.timeSlotsLength === 1) {
          this.setSelectedTimeSlot(this.validTimeSlots[0]);
        }
      })
      .catch(() => {
        this.setLoading(false);
        redirectTo("/404/");
      })
      .finally(() => {
        if (!dynamic) this.setLoading(false);
      });
  };

  @action initRecipient = (id: string | number, dynamic = false) => {
    this.setRecipientID(id);
    this.loadRecipientData(dynamic);
  };

  @action clearRecipient = () => {
    this.setRecipientData(null);
    this.setLoading(true);
    this.setSelectedTimeSlot(null);
    this.setRecipientID(null);
  };

  @action registerRecipient = (id: number) => {
    if (this.selectedTimeSlot && id) {
      this._client
        .post("/api/address_needs_joined_users/", {
          address_need_id: id,
          timeslot_id: this.selectedTimeSlot.id,
        })
        .then(() => {
          this.initRecipient(id, true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  @action unRegisterRecipient = (joined_id: number) => {
    return this._client
      .delete(`/api/address_needs_joined_users/${joined_id}/`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  @action recipientChangeTimeSlot = (id: number) => {
    if (this.recipientUserStatus.joined) {
      this.unRegisterRecipient(this.recipientUserStatus.joined.id)
        .then(() => {
          this.setSelectedTimeSlot(null);
          this.initRecipient(id, true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  @action clearRecipientDonorNeeds = () => {
    this.setBloodStationRecipientNeeds(null);
    this.setRecipientID(null);
  };

  @action setMainLoading = (v: boolean) => (this._mainLoading = v);

  @action getRecipientDonorNeedsMain = (isMainPage = false, id: number) => {
    this.setMainLoading(true);
    let params = {};
    if (isMainPage) {
      params = {
        main_page: true,
        city_id: this._selectedCityStore.cityID,
      };
    }
    this._client
      .get(this._url, { params: params })
      .then((res) => {
        this.setBloodStationRecipientNeeds(
          res.data.results?.filter((item) => item.id != id)
        );
        this.setMainLoading(false);
      })
      .catch((err) => {
        console.error(err);
        this.setMainLoading(false);
      });
  };

  @action getRecipientDonorNeedsBS = (blood_station_id: number) => {
    return this._client
      .get(this._url, { params: { blood_station: blood_station_id } })
      .then((res) => {
        return res.data.results;
      });
  };
}

export default RecipientStore;
