import styles from "./selectCityModal.module.scss";
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { inject, observer } from "mobx-react";
import { sendMetrics } from "../../utils/misc";

@inject("uiStore")
@inject("selectedCityStore")
@inject("authStore")
@inject("citiesStore")
@observer
export default class SelectCityModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleModal = () => {
    this.setState({
      open: !this.state.open,
    });
    if (this.props.modalBack) {
      this.props.modalBack();
    }
  };

  setMoscow = (city) => {
    const prevCity = this.props.selectedCityStore.city;
    this.props.selectedCityStore.setCity(city);
    this.toggleModal();
    if (city !== prevCity) location.reload();
  };

  closeModal = () => {
    this.toggleModal();
  };

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen !== prevProps.modalOpen) {
      this.setState({
        open: this.props.modalOpen,
      });
    }
  }

  render() {
    const isMoscow = this.props.selectedCityStore.city === "Moscow";
    return (
      <Modal
        open={this.state.open}
        onClose={this.closeModal}
        style={
          this.props.uiStore.isMobile
            ? {
                top: "auto",
                width: "100%",
                margin: 0,
                height: "auto",
                bottom: 0,
                borderRadius: "6px 6px 0 0",
              }
            : {
                top: "auto",
                margin: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",
                height: "auto",
                width: "auto",
              }
        }
      >
        <Modal.Content>
          <div className={styles.SelectCityModal}>
            <div
              className={styles.SelectCityModal_Close}
              onClick={this.closeModal}
            >
              <img src="/static/images/mainModalClose.svg" alt="" />
            </div>
            <div className={styles.SelectCityModal_Content}>
              <div className={styles.SelectCityModal_Content_Title}>
                <span>Выберите город</span>
              </div>
              <div className={styles.SelectCityModal_Content_Block}>
                <div
                  className={
                    isMoscow
                      ? styles.SelectCityModal_Content_Block_Moscow_Active
                      : styles.SelectCityModal_Content_Block_Moscow
                  }
                  onClick={() => {
                    this.setMoscow("Moscow");
                    sendMetrics("choice_moscow");
                  }}
                >
                  <div
                    className={
                      styles.SelectCityModal_Content_Block_Moscow_Image
                    }
                  >
                    <img src="/static/images/mainMoscow.svg" alt="" />
                  </div>
                  <div
                    className={styles.SelectCityModal_Content_Block_Moscow_Text}
                  >
                    <span>Москва</span>
                    <p>
                      Отображение данных анонсов, потребностей и мероприятий по
                      Москве и Московской области
                    </p>
                  </div>
                </div>
                <div
                  className={
                    isMoscow
                      ? styles.SelectCityModal_Content_Block_Other
                      : styles.SelectCityModal_Content_Block_Other_Active
                  }
                  onClick={() => {
                    this.setMoscow("Other");
                    sendMetrics("choice_other");
                  }}
                >
                  <div
                    className={styles.SelectCityModal_Content_Block_Other_Image}
                  >
                    <img src="/static/images/mainOther.svg" alt="" />
                  </div>
                  <div
                    className={styles.SelectCityModal_Content_Block_Other_Text}
                  >
                    <span>Другие регионы</span>
                    <p>Данные собранные со всей России и стран СНГ</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
