import {action, computed, observable} from "mobx";

export default class SpinnerStore {
  @observable toggled: boolean = false;

  constructor() {
    this.toggled = false;
  }

  @action toggleSpinner() {
    this.toggled = !this.toggled;
  }

  @computed get stateSpinner() {
    return this.toggled;
  }
}
