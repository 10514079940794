import {AxiosInstance} from "axios";
import {action, IAction} from "mobx";
import Resource from "./Resource";

export default class WidgetStore {
  url: string;
  client: AxiosInstance;
  loadWidget: (() => Promise<Resource[]>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;

    this.loadWidget = action(() => {
      return this.client
        .get(this.url)
        .then(response => {
          return response.data;
        })
    });

  }
}
