import { observable, toJS } from "mobx";

type TInputData = {
  [key: string]: {
    required?: boolean;
    default_error?: string;
    value?: any;
    placeholder?: string;
    error_list?: {
      [key: string]: string;
    };
  };
};

type TError<T> = {
  [key in keyof T]?: string;
};

abstract class DefaultFormModel<Model> {
  @observable errorList: TError<Model>;
  @observable requiredFields: string[];
  @observable inputData: TInputData;

  protected constructor(inputData: TInputData) {
    this.inputData = inputData;
    this.errorList = {};
    this.requiredFields = Object.keys(this.inputData)
      .map((key) => (this.inputData[key].required ? key : null))
      .filter(Boolean);
  }

  public Instantiate = (inputData: TInputData) =>
    Object.assign(
      this,
      ...Object.keys(inputData).map((key) => ({ [key]: inputData[key].value }))
    );

  setError = (error, text: string) => {
    if (this.inputData[error]?.error_list?.hasOwnProperty(text)) {
      this.errorList[error] = this.inputData[error].error_list[text];
    } else {
      this.errorList[error] = text;
    }
  };

  setValueByPropertyMultiple(prop, value) {
    if (prop in this) {
      if (this[prop].includes(value)) {
        this[prop] = toJS(this[prop].filter((p) => p !== value));
      } else {
        this[prop] = toJS([...this[prop], value]);
      }
      this.setError(prop, undefined);
    }
  }

  setValueByProperty(prop, value) {
    if (prop in this) {
      this[prop] = value;
      this.setError(prop, undefined);
    }
  }

  isArrayAndEmpty(val) {
    if (!val) return true;
    if (Array.isArray(val)) {
      return !val.length;
    }
  }

  isObjectAndEmpty(val) {
    if (typeof val === "object")
      return Object.keys(val)
        .map((key) => {
          return val[key] === undefined || typeof val[key] === "undefined";
        })
        .some((cond) => cond);
    else return false;
  }

  isFieldNotEmptyButOnlySpaces(val) {
    if (typeof val !== "string") return false;
    if (!val?.length) return true;
    if (!val?.replace(/ /g, "")?.length) return true;
  }

  checkInputsState() {
    this.errorList = {};
    this.requiredFields.map((f) => {
      const field = toJS(f);
      if (typeof this[field] !== typeof this.inputData[field].value) {
        this.setError(field, "Проверьте правильность типа введенного поля");
      }
      if (
        !toJS(this[field]) ||
        this.isArrayAndEmpty(toJS(this[field])) ||
        this.isObjectAndEmpty(this[field]) ||
        this.isFieldNotEmptyButOnlySpaces(this[field])
      ) {
        this.setError(
          field,
          this.inputData[field].default_error || "Поле не может быть пустым"
        );
      }
    });
    return this.errorList;
  }
}

export default DefaultFormModel;
