import {AxiosInstance, AxiosResponse} from "axios";
import {action, IAction, IObservableArray, observable} from "mobx";
import Resource from "./Resource";

export default class PlannedStore {
  url: string;
  client: AxiosInstance;
  records: IObservableArray;
  loadPlan: (() => Promise<Resource[]>) & IAction;
  savePlan: ((data) => Promise<AxiosResponse<any>>) & IAction;
  deletePlan: ((id) => Promise<AxiosResponse<any>>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.loadPlan = action(() => {
      return this.client
        .get(this.url + 'latest/')
        .then(response => {
          return response.data;
        })
    });

    this.savePlan = action((data) => {
      if (data.id) {
        return this.client
          .patch(this.url + `${data.id}/`, data)
      } else {
        return this.client
          .post(this.url, data)
      }
    })

    this.deletePlan = action((id) => {
      return this.client
        .delete(this.url + `${id}/`)
    })

  }

  all() {
    return this.records;
  }

}
