import React from "react";

const ErrorPage = () => {
  return (
    <div className={'container'}>
      <div style={{
        padding: '32px',
        textAlign: 'center',
        fontSize: '24px',
        lineHeight: 1.5,
        minHeight: '70vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
            <span>На данный момент раздел недоступен, попробуйте зайти позже. Если проблема не исчезнет напишите нам на
              <a href="mailto:support@donorsearch.org"> support@donorsearch.org</a></span>
      </div>
    </div>

  )
}

export default ErrorPage;
