import {AxiosInstance} from "axios";
import {action, IAction, IObservableValue, observable, runInAction} from "mobx";
import Resource from "./Resource";

export default class DonorCardStore {
  url: string;
  client: AxiosInstance;
  load: (() => Promise<Resource>) & IAction;
  update: ((data) => Promise<Resource>) & IAction;
  record: IObservableValue<Resource>;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;
    this.record = observable.box(null);

    this.load = action(() => {
      return this.client
        .get(this.url)
        .then(response => {
          runInAction(() => {
            this.record.set(response.data)
          });
          return response.data;
        })
    });

    this.update = action((data) => {
      return this.client
        .patch(this.url, data)
        .then(response => {
          runInAction(() => {
            this.record.set(response.data)
          });
          return response.data;
        })
    });
  }

  get() {
    return this.record.get();
  }
}
