import { action, computed, observable, toJS } from "mobx";
import { AxiosInstance } from "axios";
import { BloodGroup, City, DonationAgg } from "../entities";
import moment from "moment";
import redirectTo from "../utils/redirectTo";
import { IBloodStation } from "../components/profileThankYouPage/profileThankYouPage";

export type TEventData = {
  id: number;
  city: City;
  city_id: number;
  time_slots: TEventTimeSlot[];
  joined_users: TUser[];
  reports: TEventReport[];
  author: TActionAuthor;
  category: TEventCategory;
  name: string;
  description: string;
  donors_requirements: TDonorsRequirement[];
  address: string;
  joined_count: number;
  content_type: number;
  blood_station: IBloodStation;
  start_date: string;
  end_date: string;
  individual_partners: TIndividualPartner[];
  type: TEventType;
  user_status: TUserStatus;
  unity_partners?: EvantPartners;
};

type EvantPartners = {
  id: number;
  image: string;
  name: string;
  _image?: string;
}[];

type TDonorsRequirement = {
  event: number;
  id: number;
  requirement: string;
};

export type TUserStatus = {
  action: TUserStatusAction;
  joined: TUserStatusJoined | null;
};

type TUserStatusJoined = {
  id: number;
  event_id: number;
  status: number;
  timeslot_id: number;
  timeslot: TEventTimeSlot;
};

type TUserStatusAction =
  | "add_donation"
  | "change"
  | "create"
  | "donation_added";

export type TEventType = "past" | "today" | "future";

export type TIndividualPartner = {
  id: number;
  name: string;
  url: string;
  image: string;
  cropping: string;
  event: number;
};

export type TEventTimeSlot = {
  id: number;
  joined_count: number;
  date: string;
  start_time: string;
  end_time: string;
  count: number;
};

type TUserDonorStatus = {
  id: number;
  name: string;
  donations_count: number;
  background_color: string;
  text_color: string;
};

export type TUserData = {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  blood_group: BloodGroup;
  photo: TUserPhoto;
  legacy_avatar: string | null;
  honor_donor: boolean;
  donor_status: TUserDonorStatus;
  city: City;
  donation_agg: DonationAgg;
  phone?: string;
  email?: string;
};

export type TUser = {
  user: TUserData;
};

type TUserPhoto = {
  small: string;
  profile: string;
  medium: string;
  large: string;
  original: string;
};

type TEventReportImage = {
  image: string;
};

type TEventReport = {
  id: number;
  saved_lives: number;
  donors: number;
  blood: number;
  images: TEventReportImage[];
};

type TEventCategory = {
  id: number;
  name: "Выездная акция" | "В Центре Крови";
};

export type TActionAuthor = {
  image: string;
  name: string;
  type: "bloodstation" | "organization" | "anonymous" | "user";
  id?: number;
};

const sortFunc = (as: TEventTimeSlot, bs: TEventTimeSlot) => {
  const a = moment(as.date).add(as.start_time).toDate();
  const b = moment(bs.date).add(bs.start_time).toDate();
  if (new Date(a) > new Date(b)) {
    return 1;
  }
  if (new Date(a) < new Date(b)) {
    return -1;
  }
  return 0;
};

class EventStore {
  @observable _url: string;
  @observable _client: AxiosInstance;
  @observable private _loading: boolean;
  @observable private _isJoinedUsersModalOpen = false;

  constructor(url: string, client: AxiosInstance) {
    this._url = url;
    this._client = client;
  }

  @observable _bloodStationEvents: TEventData[] = [];

  @computed get bloodStationEvents() {
    return toJS(this._bloodStationEvents);
  }

  @observable private _eventID: number | string;

  @computed get eventID() {
    return toJS(this._eventID);
  }

  @computed get isModalOpen() {
    return toJS(this._isJoinedUsersModalOpen);
  }

  @observable private _eventData: TEventData;

  @computed get eventData() {
    return toJS(this._eventData);
  }

  @observable private _selectedDate: string;

  @computed get selectedDate() {
    return toJS(this._selectedDate);
  }

  @observable private _selectedTime: string;

  @computed get selectedTime() {
    return toJS(this._selectedTime);
  }

  @observable private _selectedTimeSlot: TEventTimeSlot;

  @computed get selectedTimeSlot() {
    return toJS(this._selectedTimeSlot);
  }

  @computed get isLoading() {
    return toJS(this._loading);
  }

  @computed get eventCategory() {
    return toJS(this.eventData?.category || null);
  }

  @computed get eventDate() {
    return {
      event_start: toJS(this.eventData?.start_date),
      event_end: toJS(this.eventData?.end_date),
    };
  }

  @computed get eventAuthor() {
    return toJS(this.eventData?.author);
  }

  @computed get eventTimeSlots() {
    return toJS(this.eventData?.time_slots);
  }

  @computed get eventPartners() {
    return toJS(this.eventData?.individual_partners);
  }

  @computed get isEventEnded() {
    if (!this.eventDate) return null;

    const nowDate = new Date();
    if (nowDate < new Date(this.eventDate.event_start)) return null;

    if (nowDate > new Date(this.eventDate.event_end)) return true;
    if (
      nowDate >= new Date(this.eventDate.event_start) &&
      nowDate < new Date(this.eventDate.event_end)
    )
      return false;
  }

  @computed get availableDates() {
    if (this.eventTimeSlots) {
      return this.eventTimeSlots
        .filter(
          (v, i, a) =>
            a.findIndex((t) => {
              if (moment(t.date).add(t.start_time).toDate() >= new Date())
                return t.date === v.date;
            }) === i
        )
        .sort(sortFunc);
    }
  }

  @computed get availableTimes() {
    if (this.eventTimeSlots && this.selectedDate)
      return this.eventTimeSlots
        .filter((item) => {
          return item.date === this.selectedDate;
        })
        .sort(sortFunc);
    else return [];
  }

  @computed get allAvailableTimes() {
    if (this.eventTimeSlots) {
      if (this.selectedDate) {
        return this.eventTimeSlots.filter(
          (timeslot) => timeslot.date === this.selectedDate
        );
      } else {
        return this.eventTimeSlots.filter(
          (v, i, a) => a.findIndex((t) => t.start_time === v.start_time) === i
        );
      }
    }
  }

  @computed get eventUserStatus() {
    if (this.eventData?.user_status) return this.eventData.user_status;
  }

  @observable _bloodStationEventsLoading = false;

  @computed get bloodStationEventsLoading() {
    return toJS(this._bloodStationEventsLoading);
  }

  @computed
  get allAvailableTimesCurrentDateCheck() {
    if (this.eventTimeSlots) {
      return this.eventTimeSlots.filter(
        (timeSlot) =>
          moment(timeSlot.date).add(timeSlot.start_time) >= moment().utc()
      );
    }
  }

  @computed get isEventEndAndBeforeThreeDays() {
    return (
      moment().diff(moment(this.eventData?.end_date).utc(), "days") + 1 <= 3
    );
  }

  @computed get userNotSignedUpBut() {
    if (this.isEventEndAndBeforeThreeDays) {
      if (!this.eventUserStatus?.joined) {
        return !this.allAvailableTimesCurrentDateCheck?.length;
      }
      return false;
    }
    return false;
  }

  @action setIsModalOpen = (v: boolean) => (this._isJoinedUsersModalOpen = v);

  @action getAvailableTimesFromDate = (v: string) => {
    if (this.eventTimeSlots && v) {
      return this.eventTimeSlots.filter((item) => item.date === v);
    }
  };

  @action getEventTimeSlotByJoinedTimeSlot = (v: TEventTimeSlot) => {
    if (this.eventTimeSlots)
      return this.eventTimeSlots.find((timeslot) => {
        return timeslot.id === v.id;
      });
  };

  @action setSelectedTimeSlot = (v: TEventTimeSlot) =>
    (this._selectedTimeSlot = v);

  @action setSelectedTime = (v: string) => (this._selectedTime = v);

  @action setSelectedDate = (v: string) => (this._selectedDate = v);

  @action setEventData = (data: TEventData) => (this._eventData = data);

  @action setEventID = (v: number | string) => (this._eventID = v);

  @action setLoading = (v: boolean) => (this._loading = v);

  @action loadEventData = (dynamically = false) => {
    if (!dynamically) this.setLoading(true);
    return this._client
      .get(`${this._url}${this.eventID}/`)
      .then((res) => {
        this.setEventData(res.data as TEventData);
      })
      .catch((err) => {
        console.error(err);
        this.setLoading(false);
        redirectTo("/404/");
      })
      .finally(() => {
        if (!dynamically) this.setLoading(false);
      });
  };

  @action initEvent = (id: number | string, dynamically = false) => {
    this.setEventID(id);
    this.loadEventData(dynamically);
  };

  @action clearEvent = () => {
    this.setEventData(null);
    this.setSelectedDate(null);
    this.setSelectedTime(null);
    this.setSelectedTimeSlot(null);
    this.setLoading(true);
  };

  @action eventSignUp = () => {
    if (this.selectedTimeSlot && this.eventID) {
      this._client
        .post("/api/joined_users/", {
          event_id: this.eventID,
          timeslot_id: this.selectedTimeSlot.id,
        })
        .then(() => {
          this.initEvent(this.eventID, true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  @action eventSignOut = (joined_id: number) => {
    return this._client
      .delete(`/api/joined_users/${joined_id}/`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  @action eventChangeTimeSlot = () => {
    if (this.eventUserStatus.joined) {
      this.eventSignOut(this.eventUserStatus.joined.id)
        .then(() => {
          this.setSelectedDate(null);
          this.setSelectedTime(null);
          this.setSelectedTimeSlot(null);
          this.initEvent(this.eventID, true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  @action getEventsByBloodStationId = (bloodStationId: number) => {
    return this._client
      .get(`/api/events/?by_blood_station=${bloodStationId}`)
      .then((res) => {
        return res.data.results;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  @action getEventsForMainPage = (city_id?: number | string) => {
    return this._client
      .get(`/api/events/?city_id=${city_id}&main_page=true&by_date=main_future`)
      .then((res) => {
        return res.data.results;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  @action setBloodStationEventsLoading = (v: boolean) =>
    (this._bloodStationEventsLoading = v);

  @action setBloodStationEvents = (v: TEventData[]) =>
    (this._bloodStationEvents = v);

  @action getEventsForBloodStationById = async (id: number) => {
    this.setBloodStationEventsLoading(true);
    this.getEventsByBloodStationId(id).then((res) => {
      this.setBloodStationEvents(res);
      this.setBloodStationEventsLoading(false);
    });
  };

  @action clearEventsForBloodStation = () => {
    this.setBloodStationEvents([]);
  };
}

export default EventStore;
