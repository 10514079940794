import { AxiosInstance } from "axios";
import { action, IAction } from "mobx";
import Resource from "./Resource";

export default class DonationsBeforeRegStore {
  url: string;
  client: AxiosInstance;
  sendCountDonations: ((data) => Promise<Resource[]>) & IAction;
  getCountDonations: (() => Promise<Resource[]>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;

    this.sendCountDonations = action((data) => {
      return this.client.patch(this.url, data).then((response) => {
        return response.data;
      });
    });
    this.getCountDonations = action(() => {
      return this.client.get(this.url).then((response) => {
        return response.data;
      });
    });
  }
}
