import { action, computed, observable } from "mobx";
import { AxiosInstance, AxiosResponse } from "axios";
import TokenStore from "./TokenStore";
import { User } from "../entities";
import { AchievementData } from "../components/achievements/types";

class AuthStore {
  client: AxiosInstance;
  @observable user: User = null;
  @observable backendUser: User = null;
  tokenStore: TokenStore;
  errorAuth: boolean;
  @observable takenAchievements: [];
  @observable achievements: AchievementData[];

  constructor(client: AxiosInstance, initialData, tokenStore) {
    this.client = client;
    this.user = initialData?.user;
    this.backendUser = initialData?.user;
    this.errorAuth = false;
    this.tokenStore = tokenStore;
    this.takenAchievements = [];
    this.achievements = [];
  }

  getCountOfVisits() {
    if (this.tokenStore.get()) {
      return this.client.get("/api/profile/")
      .then((response) => response)
      .catch((err) => console.log(err));
    }
  }

  fetchCurrentUser(): Promise<User> {
    if (this.tokenStore.get()) {
      return this.client
        .get<User>("/api/auth/me/")
        .then((response) => {
          this.setUser(response.data);
          return this.user;
        })
        .catch((err) => {
          this.errorAuth = true;
          if (err.response?.status === 401) {
            this.tokenStore.remove();
            this.setUser(null);
          }
          return new Promise((resolve) => {
            this.setUser(null);
            resolve(null as User);
          });
        });
    }

    return new Promise((resolve) => {
      this.setUser(null);
      resolve(null as User);
    });
  }

  update(user: User) {
    return this.client.patch<User>("/api/auth/me/", user).then((response) => {
      this.setUser(response.data);
      return this.user;
    });
  }

  updateMetadata(user: User) {
    return this.client.patch<User>(`/api/auth/${this.user?.id}/metadata/`, user).then((response) => {
      // this.setUser(response.data);
      // return this.user;
    });
  }

  login(username: string, password: string): Promise<User> {
    return this.client
      .post<User>("/api/auth/login/", JSON.stringify({ username, password }))
      .then((response) => {
        // Если раскоментеровать эту часть кода, тогда закоментировать this.handleAuth(response);
        // const token = response.headers.token;
        // document.cookie = `token=${token};domain=.donorsearch.org`;
        // console.log(document.cookie.split("").splice(6, 40).join(""))
        // this.setToken(document.cookie.split("").splice(6, 40).join(""));

        this.handleAuth(response);
        this.setUser(response.data);
        return this.user;
      });
  }

  registration(
    phone: string,
    password: string,
    first_name: string,
    tag: string,
    referralCode?: string | string[]
  ): Promise<any> {
    if (phone.slice(0, 1) === "+") {
      return this.client.post<User>(
        `/api/auth/registration/${referralCode ? `?referral_code=${referralCode}` : ""}`,
        JSON.stringify({ phone, password, first_name, tag })
      );
    } else {
      const email = phone;
      return this.client.post<User>(
        `/api/auth/registration/${referralCode ? `?referral_code=${referralCode}` : ""}`,
        JSON.stringify({ email, password, first_name, tag })
      );
    }
  }

  change_email(email: string): Promise<any> {
    return this.client
      .post<User>("/api/auth/change_email/", { email: email })
      .then((response) => {
        return response;
      });
  }

  confirm_email(email: string, code: number | string): Promise<any> {
    return this.client
      .post<User>("/api/auth/confirm_email/", { email: email, code: code })
      .then((response) => {
        return response;
      });
  }

  change_phone(phone: string): Promise<any> {
    return this.client
      .post<User>("/api/auth/change_phone/", { phone: phone })
      .then((response) => {
        return response;
      });
  }

  confirm_phone(phone: string, code: number | string): Promise<any> {
    return this.client
      .post<User>("/api/auth/confirm_phone/", { phone: phone, code: code })
      .then((response) => {
        return response;
      });
  }

  confirm_phone_reg(
    user_id: number,
    phone: string,
    code: string
  ): Promise<any> {
    return this.client
      .post<User>(
        "/api/auth/confirm_phone_reg/",
        JSON.stringify({ user_id, phone, code })
      )
      .then((response) => {
        this.handleAuth(response);
        this.setUser(response.data);
        return this.user;
      });
  }

  confirm_email_reg(
    user_id: number,
    phone: string,
    code: string
  ): Promise<any> {
    const email = phone;
    return this.client
      .post<User>(
        "/api/auth/confirm_email_reg/",
        JSON.stringify({ user_id, email, code })
      )
      .then((response) => {
        this.handleAuth(response);
        this.setUser(response.data);
        return this.user;
      });
  }

  resend_code(user_id: number): Promise<any> {
    return this.client.post<User>(
      "/api/auth/resend_code/",
      JSON.stringify({ user_id })
    );
  }

  later_confirm_email(): Promise<any> {
    return this.client.post<User>("/api/auth/email_widget_link_checked/");
  }

  resend_code_email(user_id: number): Promise<any> {
    return this.client.post<User>(
      "/api/auth/resend_email_code/",
      JSON.stringify({ user_id })
    );
  }

  changePassword(user: User) {
    return this.client
      .post<User>("/api/auth/change_password/", user)
      .then((response) => {
        this.handleAuth(response);
        this.setUser(response.data);
        return this.user;
      });
  }

  recoverPassword(username: string) {
    return this.client
      .post("/api/auth/recover/", { username: username })
      .then((response) => {
        return response;
      });
  }

  getTokenFromCode(code: string) {
    return this.client.post("/api/auth/check_sms_code/", { code: code });
  }

  recoverPasswordPhone(token: string, password: string) {
    return this.client
      .post("/api/auth/set_password/", {
        token: token,
        password: password,
      })
      .then((response) => {
        this.handleAuth(response);
        this.setUser(response.data);
        return this.user;
      });
  }

  handleAuth(response: AxiosResponse) {
    this.setToken(response.headers.token);
  }

  deleteSocial(id: number) {
    return this.client.delete(`/api/socials/${id}/`);
  }

  getSocial() {
    return this.client.get("/api/socials/");
  }

  getReferrals() {
    const id = this.user?.id;
    return this.client.get(`/api/auth/${id}/referrals/`,).then(resp => resp.data)
  }

  getAchievements(id: number) {
    return this.client.get(`/api/auth/${id}/achievements/`).then(resp => resp.data)
  }


  takeAchievementByType(id: number, type: string) {
    return this.client.patch(`/api/auth/${id}/achievements/`, { achievement_type: type }).then(resp => resp.data)
  }

  @action setToken(token: string) {
    this.tokenStore.set(token);
  }

  @action setUser(user) {
    this.user = user;
  }

  @action logOut(token: string) {
    this.user = null;
    this.tokenStore.remove();
  }

  @action setTakenAchievements(ach) {
    this.takenAchievements = ach;
  }

  @action setAchievements(ach) {
    this.achievements = ach;
  }

}

export default AuthStore;