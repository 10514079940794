import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, IAction, observable} from "mobx";

export default class BannersStore extends ResourseStore {
  url: string;
  client: AxiosInstance;
  loads: (() => Promise<any[]>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.loads = action((): Promise<any[]> => {
      return this.client.get(this.url).then(resp => resp.data.results)
    })
  }
}
