import React, {Component} from 'react';
import ErrorPage from "./errorPage";

export default class ErrorBoundary extends Component {
  state: {
    hasError: boolean;
  }

  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage/>
      )
    }

    return this.props.children;
  }
}
