import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, observable} from "mobx";

export default class SurveyStore extends ResourseStore {
  url: string;
  client: AxiosInstance;
  addSurveyAnswerAfterDonation: ((data: {id: string, rating: string, comment: string}) => any);
  getSurveyAnswerAfterDonation: ((id: string) => any);
  
  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.addSurveyAnswerAfterDonation = action((data: {id: string, rating: string, comment: string}) => {
      return this.client.post(`/api/survey_after_donation/`, data).then(resp => resp.data)
    })

    this.getSurveyAnswerAfterDonation = action((id: string) => {
      return this.client.get(`/api/donations/${id}/surveys/`).then(resp => resp.data)
    })
  }
}