import React from 'react';
import styles from './dsLogo.module.scss';
import redirectTo from "../../utils/redirectTo";
import {sendMetrics} from "../../utils/misc";

function DSLogo({isActiveLink = '', link = null, isMenuOpen, isAlt = false, metric = null, isMobile = false, isHeaderLogo = false}) {
  const redirectWithMetrics = (link, metric) => {
    if (metric)
      sendMetrics(metric)
    redirectTo(link)
  };
//   if (!isAlt){
      return (
        <>
          <div className={`${styles.DSLogo}  ${isHeaderLogo ? styles.headerLogo: ''}`}
               onClick={() => redirectWithMetrics(link, metric)}>
              <div>
                  
                <img className={styles.DSImage} src={"/static/images/newLogoIcon.svg"} alt=""/>
                  
              </div>
              {/* <div>
                  <span className={`${isHeaderLogo ? styles.DSLogoHeader : ''} ${isActiveLink === '/' && !isMenuOpen ? styles.DSLogoActiveLink : ''}`}>{isHeaderLogo && isMobile  ? 'Главная' : 'DonorSearch'}</span>
              </div> */}
          </div>
          </>
      )
//   } else {
//       return (
//           <div className={styles.DSLogo} onClick={() => redirectWithMetrics(link, metric)}>
//               <div>
//                   <img width="25" className={styles.DSImage} src={"/static/images/logoIcon.svg"} alt=""/>
//               </div>
//               <div>
//                   <span style={{fontSize: '15px'}}>DonorSearch</span>
//               </div>
//           </div>
//       )
//   }



  /*return (
    <div className={styles.DSLogo} onClick={() => redirectWithMetrics(link, metric)}>
      <div>
          <img className={styles.DSImage} src={"/static/images/logoIcon.svg"} alt=""/>
      </div>
      <div>
        <span>DonorSearch</span>
      </div>
    </div>
  )*/
}
export default React.memo(DSLogo)