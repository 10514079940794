import DefaultFormModel from "./defaultModel";
import { observable } from "mobx";

const REQUIREMENT_STEP_INFO = {
  blood_class: {
    required: true,
    default_error: "Компонент крови не выбран",
    value: "",
  },
  requested_blood_type: {
    required: true,
    default_error: "Группа крови не выбрана",
    value: [],
  },
  required_donors_count: {
    required: true,
    default_error: "Указано неверное количество доноров крови",
    value: 10,
  },
  city: {
    required: true,
    default_error: "Город не выбран",
    value: {
      label: undefined,
      value: undefined,
    },
  },
  blood_station: {
    required: true,
    placeholder: "Выберите центр крови",
    default_error: "Центр крови не выбран",
    value: {
      label: undefined,
      value: undefined,
    },
  },
  last_date: {
    required: true,
    placeholder: "Выберите дату",
    default_error: "Дата окончания поиска не выбрана",
    value: "",
  },
};

export type TCity = {
  value: number;
  label: string;
};

class RequirementStep extends DefaultFormModel<typeof REQUIREMENT_STEP_INFO> {
  @observable blood_class: string;
  @observable requested_blood_type: string[];
  @observable required_donors_count: number;
  @observable city: TCity;
  @observable blood_station: TCity;
  @observable last_date: string;

  constructor() {
    super(REQUIREMENT_STEP_INFO);
    this.Instantiate(REQUIREMENT_STEP_INFO);
    this.city = undefined;
    this.blood_station = undefined;
  }
}

export default RequirementStep;
