import { action, computed, observable, toJS } from "mobx";

export default class AddDonationStore {
  @observable _preData;

  @computed get preData() {
    return toJS(this._preData);
  }

  @action setPreData = (v) => {
    this._preData = v;
  };
}
