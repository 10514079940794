import React from "react";
import styles from "./UserAvatar.module.scss";
import { User } from "../../../entities";

type Props = {
  user: User;
  style?: React.CSSProperties;
  scale?: number;
  field?: string;
};

const UserAvatar: React.FC<Props> = ({ user, style, scale = 48, field }) => {
  if (field && user) {
    if (user[field]) {
      return (
        <div style={style} className={styles.UserPhoto}>
          <img
            width={scale}
            height={scale}
            src={user[field]}
            alt="User photo"
          />
        </div>
      );
    }
  }

  if (user?.photo) {
    return (
      <div style={style} className={styles.UserPhoto}>
        <img
          width={scale}
          height={scale}
          src={user.photo?.small}
          alt="User photo"
        />
      </div>
    );
  } else {
    return (
      <div style={style} className={styles.DefaultUserPhoto}>
        <img
          width={scale}
          height={scale}
          src="/static/images/icons/defaultUserAvatar.svg"
          alt="default User photo"
        />
      </div>
    );
  }
};

export default UserAvatar;
