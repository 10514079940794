import ResourseStore from "./ResourseStore";
import { AxiosInstance } from "axios";
import { action, IAction, observable } from "mobx";

export default class TopDonorsStore extends ResourseStore {
  url: string;
  client: AxiosInstance;
  loads: ((filter: any) => Promise<any[]>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.loads = action((filter): Promise<any[]> => {
      return this.client
        .get(this.url, { params: filter })
        .then((resp) => resp.data);
    });
  }
}
