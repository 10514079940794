import Cookie from "mobx-cookie";
import { action, computed, observable } from "mobx";
import { AxiosInstance } from "axios";
import { City } from "../entities";
import removeCookie from "../hooks/removeCookie";

class SelectedCityStore {
  @observable private _client: AxiosInstance;
  @observable private _url: string;

  @observable private _city: string;
  @observable private _cityID: number;
  @observable private _header_city: string;

  @observable private _cityData: City;

  constructor(initialData, client: AxiosInstance, url: string) {
    this._client = client;
    this._url = url;

    this._header_city = initialData?.header_city;
    this._city = initialData?._city;
    this._cityID = initialData?._cityID;

    this._cityData = initialData?._cityData;
  }

  @action setCityDataFromBack = (id: number) => {
    return this._client
      .get(`${this._url}${id}`)
      .then((res) => this.setCityData(res.data));
  };

  @action setCity = (v: string) => {
    this._city = v;
    const cookie = new Cookie("City");
    cookie.set(v, { expires: 7, domain: ".donorsearch.org" });
    cookie.set(v, { expires: 7, domain: "unity.redcross.ru" });
  };

  @action setCityId = async (v: number | undefined) => {
    this._cityID = v;
    const cookie = new Cookie("City_id");
    if (v) {
      cookie.set(v.toString(), { expires: 7, domain: ".donorsearch.org" });
      cookie.set(v.toString(), { expires: 7, domain: "unity.redcross.ru" });
      await this.setCityDataFromBack(v);
    } else {
      removeCookie("City_id", ".donorsearch.org")
      removeCookie("City_id", "unity.redcross.ru");
      cookie.remove();
      this.setCityData(undefined);
    }
  };

  @action setHeaderCity = (v: string) => (this._header_city = v);

  @action setCityData = (v: City) => (this._cityData = v);

  @computed get isMoscow() {
    return this._city === "Moscow";
  }

  @computed get isTatar() {
    // Tatarstan region ID = 41
    return this._cityData?.region_id === 41;
  }

  @computed get city() {
    return this._city;
  }

  @computed get cityID() {
    return this._cityID;
  }

  @computed get cityData() {
    return this._cityData;
  }
}

export default SelectedCityStore;
