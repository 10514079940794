import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, observable} from "mobx";

export default class CoDonationsStore extends ResourseStore {
  url: string;
  client: AxiosInstance;

  joinCollectiveDonation: ((id: any) => any);
  leaveCollectiveDonation: ((id: any) => any);
  getMyCollectiveDonations: (() => any);
  getLastPlanning: (() => any);
  saveCollectivePlan: ((data: any) => any);
  changeCollectivePlan: ((id: any, data: any) => any);
  deleteCollectiveDonation: ((id: any) => any);

  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.joinCollectiveDonation = action((id: number) => {
      return this.client.post(`/api/collective_donations/${id}/join/`).then(resp => resp.data)
    })

    this.leaveCollectiveDonation = action((id: number) => {
      return this.client.post(`/api/collective_donations/${id}/leave/`).then(resp => resp.data)
    })

    this.getMyCollectiveDonations = action(() => {
      return this.client.get(`/api/profile/collective_donations/`).then(resp => resp.data)
    })

    this.getLastPlanning = action(() => {
      return this.client.get(`/api/profile/planned-donation/`).then(resp => resp.data)
    })

    this.saveCollectivePlan = action((data) => {
      return this.client.post('/api/collective_donations/', data).then(resp => resp.data)
    })

    this.changeCollectivePlan = action((id, data) => {
      return this.client.patch(`/api/collective_donations/${id}/`, data).then(resp => resp.data)
    })

    this.deleteCollectiveDonation = action((id: number) => {
      return this.client.delete(`/api/collective_donations/${id}/`).then(resp => resp.data)
    })
  }
};
