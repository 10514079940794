import { action, computed, observable } from "mobx";
import { AxiosInstance } from "axios";

type TAvailableFilters = "country" | "region" | "s";

type TFindFilter = {
  [filter in TAvailableFilters]?: string;
};

type TOption = {
  value: number;
  label: string;
};

class PlaceStore {
  @observable private _url;
  @observable private _client: AxiosInstance;

  @observable private _countryOptions: TOption[];
  @observable private _countryOptionsFilter: TFindFilter;

  @observable private _regionOptions: TOption[];
  @observable private _regionOptionsFilter: TFindFilter;

  @observable private _cityOptions: TOption[];
  @observable private _cityOptionsFilter: TFindFilter;

  @observable private _bloodStationOptions: TOption[];
  @observable private _bloodStationOptionsFilter: TFindFilter;

  @observable private _organizationOptions: TOption[];
  @observable private _organizationOptionsFilter: TFindFilter;

  constructor(url: string, client: AxiosInstance) {
    this._url = url;
    this._client = client;

    this._countryOptions = [];
    this._countryOptionsFilter = undefined;

    this._regionOptions = [];
    this._regionOptionsFilter = undefined;

    this._cityOptions = [];
    this._cityOptionsFilter = undefined;

    this._bloodStationOptions = [];
    this._bloodStationOptionsFilter = undefined;

    this._organizationOptions = [];
    this._organizationOptionsFilter = undefined;
  }

  @computed get countryOptions() {
    return this._countryOptions;
  }

  @computed get regionOptions() {
    return this._regionOptions;
  }

  @computed get cityOptions() {
    return this._cityOptions;
  }

  @computed get bloodStationOptions() {
    return this._bloodStationOptions;
  }

  @computed get organizationOptions() {
    return this._organizationOptions;
  }

  @computed get countryOptionsFilter() {
    return this._countryOptionsFilter;
  }

  @computed get regionOptionsFilter() {
    return this._regionOptionsFilter;
  }

  @computed get cityOptionsFilter() {
    return this._cityOptionsFilter;
  }

  @computed get bloodStationOptionsFilter() {
    return this._bloodStationOptionsFilter;
  }

  @computed get organizationOptionsFilter() {
    return this._organizationOptionsFilter;
  }

  @action setCountryOptions = (v: TOption[]) => (this._countryOptions = v);
  @action setRegionOptions = (v: TOption[]) => (this._regionOptions = v);
  @action setCityOptions = (v: TOption[]) => (this._cityOptions = v);
  @action setBloodStationOptions = (v: TOption[]) =>
    (this._bloodStationOptions = v);
  @action setOrganizationOptions = (v: TOption[]) =>
    (this._organizationOptions = v);

  @action setCountryOptionsFilter = (v: TFindFilter) =>
    (this._countryOptionsFilter = v);
  @action setRegionOptionsFilter = (v: TFindFilter) =>
    (this._regionOptionsFilter = v);
  @action setCityOptionsFilter = (v: TFindFilter) =>
    (this._cityOptionsFilter = v);
  @action setBloodStationOptionsFilter = (v: TFindFilter) =>
    (this._bloodStationOptionsFilter = v);
  @action setOrganizationOptionsFilter = (v: TFindFilter) =>
    (this._organizationOptionsFilter = v);

  @action updateCountryOptions = async (v: string) => {
    this.setCountryOptionsFilter({ ...this.countryOptionsFilter, s: v });
    return this._client
      .get(`${this._url}countries/`, { params: this.countryOptionsFilter })
      .then((res) => {
        const buffer = res.data.results.map((record) => ({
          value: record.id,
          label: record.title,
        }));
        this._countryOptions = buffer;
        return buffer;
      });
  };

  @action updateRegionOptions = async (v: string, country?: number) => {
    this.setRegionOptionsFilter({ ...this.regionOptionsFilter, s: v });
    return this._client
      .get(`${this._url}regions/`, {
        params: {
          ...this.regionOptionsFilter,
          country,
        },
      })
      .then((res) => {
        const buffer = res.data.results.map((record) => ({
          value: record.id,
          label: record.title,
        }));
        this._regionOptions = buffer;
        return buffer;
      });
  };

  @action updateCityOptions = async (
    v: string,
    country?: number,
    region?: number
  ) => {
    this.setCityOptionsFilter({ ...this.cityOptionsFilter, s: v });
    return this._client
      .get(`${this._url}cities/`, {
        params: {
          ...this.cityOptionsFilter,
          country,
          region,
        },
      })
      .then((res) => {
        const buffer = res.data.results.map((record) => ({
          value: record.id,
          label: record.title,
        }));
        this._cityOptions = buffer;
        return buffer;
      });
  };

  @action updateBloodStationOptions = async (
    v: string,
    country?: number,
    region?: number,
    city_id?: number
  ) => {
    this.setBloodStationOptionsFilter({
      ...this.bloodStationOptionsFilter,
      s: v,
    });
    return this._client
      .get(`${this._url}blood_stations/all_names/`, {
        params: {
          ...this.bloodStationOptionsFilter,
          country,
          region,
          city_id,
        },
      })
      .then((res) => {
        const buffer = res.data.results.map((record) => ({
          value: record.id,
          label: record.title,
        }));
        this._bloodStationOptions = buffer;
        return buffer;
      });
  };

  @action updateOrganizationOptions = async (
    v: string,
    country?: number,
    region?: number,
    city_id?: number
  ) => {
    this.setOrganizationOptionsFilter({
      ...this.organizationOptionsFilter,
      s: v,
    });
    return this._client
      .get(`${this._url}organizations/all_names/`, {
        params: { ...this.organizationOptionsFilter, country, region, city_id },
      })
      .then((res) => {
        const buffer = res.data.results.map((record) => ({
          value: record.id,
          label: record.name,
        }));
        this._organizationOptions = buffer;
        return buffer;
      });
  };

  @action resetStore = () => {
    this.setCountryOptions([]);
    this.setCountryOptionsFilter(undefined);

    this.setRegionOptions([]);
    this.setRegionOptionsFilter(undefined);

    this.setCityOptions([]);
    this.setCityOptionsFilter(undefined);

    this.setBloodStationOptions([]);
    this.setBloodStationOptionsFilter(undefined);

    this.setOrganizationOptions([]);
    this.setOrganizationOptionsFilter(undefined);
  };
}

export default PlaceStore;
