import ResourseStore, {filterQueryString} from "./ResourseStore";
import {AxiosInstance, AxiosResponse} from "axios";
import {action, IAction} from "mobx";

export default class BloodStationStore extends ResourseStore {
  saveFavorite: ((id) => Promise<AxiosResponse<any>>) & IAction;
  deleteFavorite: ((id) => Promise<AxiosResponse<any>>) & IAction;
  getDataForSearch: ((search: string, filters?) => any) & IAction;
  getTopUsersFromBS: ((id: number | string) => Promise<any>) & IAction;
  getTopUsersFromBSHalfYear: ((id: number | string) => Promise<any>) & IAction;
  getPlannedUserCount: ((id: number | string, isActual: boolean) => Promise<any>) & IAction;
  getNoticeFromBSID: ((id: number | string) => Promise<any>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    super(url, client);


    this.saveFavorite = action((id) => {
      const data = {
        'blood_station': id
      }
      return this.client
        .post('/api/favorite_blood_stations/', data)
    });

    this.deleteFavorite = action((id) => {
      return this.client
        .delete(`/api/favorite_blood_stations/${id}/`)
    });

    this.getDataForSearch = action((search, filters?) => {
      const queryString = filterQueryString(filters)
      return this.client
        .get(`${this.url}search/?q=${search}&${queryString}`)
        .then(r => r.data)
    });

    this.getTopUsersFromBS = action((id: number | string) => {
      return this.client
        .get(`${this.url}${id}/top/`)
        .then(r => r.data)
    })

    this.getTopUsersFromBSHalfYear = action((id: number | string) => {
      return this.client
        .get(`${this.url}${id}/top_halfyear/`)
        .then(r => r.data)
    })

    this.getPlannedUserCount = action((id, isActual=false) => {
      return this.client
        .get(`${this.url}${id}/planned/?page_size=3&actual_planning=${isActual}`)
        .then(r => r.data)
    })

    this.getNoticeFromBSID = action((id) => {
      return this.client
        .get(`/api/notice/?bs_id=${id}`)
        .then(r => r.data)
    })
  }

}
