import React from "react";
import styles from "./spinner.module.scss";

export default function Spinner({
  toggled = false,
  onAll = false,
  onEmptyPage = false,
  isProfileTabEnable = false,
  background = false,
  customWidth = "",
  customHeight = "",
  superSpinner = false,
  backgroundColor = null,
}) {
  const customStyle = background
    ? { background: "#F6F7F8" }
    : customWidth && customHeight
    ? { width: customWidth, height: customHeight }
    : null;

  if (superSpinner)
    return (
      <div
        style={{ ...customStyle, position: "absolute", top: 0, left: 0 }}
        className={onAll ? styles.SpinnerOnAll : styles.Spinner}
      >
        <img
          style={customStyle}
          className={styles.SpinnerAnimation}
          src="/static/images/icons/circleDots.svg"
          alt="Spinner"
        />
      </div>
    );

  if (toggled && onEmptyPage && !onAll)
    return (
      <div
        style={customStyle}
        className={
          isProfileTabEnable ? styles.OnEmptyPageProfile : styles.OnEmptyPage
        }
      >
        <div className={onAll ? styles.SpinnerOnAll : styles.Spinner}>
          <img
            style={customStyle}
            src="/static/images/icons/circleDots.svg"
            alt="Spinner"
          />
        </div>
      </div>
    );

  if (toggled)
    return (
      <div
        style={{ background: backgroundColor, ...customStyle }}
        className={onAll ? styles.SpinnerOnAll : styles.Spinner}
      >
        <img
          style={customStyle}
          className={styles.SpinnerAnimation}
          src="/static/images/icons/circleDots.svg"
          alt="Spinner"
        />
      </div>
    );
  return null;
}
