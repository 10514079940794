import React from 'react';
import styles from "./bloodStations.module.scss";
import Link from "next/link";

export const CityItem = ({title, address, slug, withIcon = true, noLink = false}) => {
  if (noLink)
    return (
      <div className={styles.CityItem}>
        <div className={styles.CityItem_Block}>
          {withIcon && <div className={styles.CityItem_Block_Image}>
              <img src="/static/images/icons/BSCityIco.svg" alt="BSCityIco"/>
          </div>}
          <div className={styles.CityItem_Block_Text}>
            <span>{title}</span>
            <p>{address}</p>
          </div>
        </div>
      </div>
    )
  else return (
    <Link href={`/bloodstations/${slug}`}>
      <div className={styles.CityItem}>
        <div className={styles.CityItem_Block}>
          {withIcon && <div className={styles.CityItem_Block_Image}>
              <img src="/static/images/icons/BSCityIco.svg" alt="BSCityIco"/>
          </div>}
          <div className={styles.CityItem_Block_Text}>
            <span>{title}</span>
            <p>{address}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}
