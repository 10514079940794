import { action, computed, observable, toJS } from "mobx";
import { AxiosInstance } from "axios";
import { City, User } from "../entities";
import { TEventData } from "./EventStore";
import { TRecipientData } from "./RecipientStore";

export type TOrganizationData = {
  id: number;
  image: string;
  city: City;
  donors: User[];
  organization_type: TOrganizationType;
  organizationsocialnetworks_set: TOrganizationSocial[];
  organizationteam_set: TOrganizationTeam[];
  created_at: string;
  updated_at: string;
  name: string;
  description: string;
  _image: string;
  cropping: string;
  email: string;
  website: string;
  phone: string;
  lives_count: number;
  events_count: number;
  donors_count: number;
  blood_count: number;
  can_create_address_needs: boolean;
  user_status: "leave" | "join";
};

export type TOrganizationTeam = {
  id: number;
  image: string;
  name: string;
  position: string;
  _image: string;
  cropping: string;
};

export type TOrganizationSocial = {
  id: number;
  social: string;
  url: string;
};

type TOrganizationType = {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
};

interface ISplitType<T> {
  actual: T;
  past: T;
}

class OrganizationStore {
  @observable private _url: string;
  @observable private _client: AxiosInstance;
  @observable private _loading: boolean;

  constructor(url: string, client: AxiosInstance) {
    this._url = url;
    this._client = client;
  }

  @observable private _organizationEvents: ISplitType<TEventData[]>;

  @observable private _organizationDonorsNeed: ISplitType<TRecipientData[]>;

  @computed get organizationEvents() {
    return toJS(this._organizationEvents);
  }

  @computed get actualOrganizationEvents() {
    return toJS(this._organizationEvents?.actual);
  }

  @computed get pastOrganizationEvents() {
    return toJS(this._organizationEvents?.past);
  }

  @computed get organizationDonorsNeed() {
    return toJS(this._organizationDonorsNeed);
  }

  @computed get actualOrganizationDonorsNeed() {
    return toJS(this._organizationDonorsNeed?.actual);
  }

  @computed get pastOrganizationDonorsNeed() {
    return toJS(this._organizationDonorsNeed?.past);
  }

  @computed get organizationUserStatus() {
    return toJS(this._organizationData?.user_status);
  }

  @observable private _organizationData: TOrganizationData;

  @computed get organizationData() {
    return toJS(this._organizationData);
  }

  @computed get isLoading() {
    return toJS(this._loading);
  }

  @action setIsLoading = (v: boolean) => (this._loading = v);
  @action setOrganizationData = (v: TOrganizationData) =>
    (this._organizationData = v);

  @action initOrganization = (id: number, dynamic = false) => {
    if (!dynamic) this.setIsLoading(true);
    this._client
      .get(`${this._url}${id}`)
      .then((res) => {
        this.setOrganizationData(res.data as TOrganizationData);
        if (!dynamic) this.setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (!dynamic) this.setIsLoading(false);
      });
  };

  @action getOrganizationEvents = (id: number) => {
    this._client.get(`/api/organizations/${id}/events/`).then((res) => {
      this._organizationEvents = res.data;
    });
  };

  @action getOrganizationDonorsNeed = (id: number) => {
    this._client.get(`/api/organizations/${id}/address_needs/`).then((res) => {
      this._organizationDonorsNeed = res.data;
    });
  };

  @action joinOrganizationDonors = (id: number) => {
    this._client
      .post(`${this._url}${id}/join/`)
      .then((res) => {
        this.setOrganizationData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  @action leaveOrganizationDonors = (id: number) => {
    this._client
      .post(`${this._url}${id}/left/`)
      .then((res) => {
        this.setOrganizationData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default OrganizationStore;
