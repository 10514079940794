import { AxiosInstance, AxiosResponse } from "axios";
import {
  action,
  IAction,
  IObservableArray,
  observable,
  runInAction,
} from "mobx";
import Resource from "./Resource";

const storeRecord = (records) => (newRecord) => {
  const existingRecord = records.find((r) => r.id === newRecord.id);
  if (existingRecord) {
    Object.assign(existingRecord, newRecord);
    return existingRecord;
  } else {
    records.push(newRecord);
    return newRecord;
  }
};

export function filterQueryString(obj: object): string {
  if (obj)
    return Object.keys(obj)
      .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
      .join("&");
  else return "";
}

export default class ResourceStore {
  url: string;
  client: AxiosInstance;
  records: IObservableArray;
  load: ((
    page_size?,
    page?,
    filters?
  ) => Promise<{
    has_user_city?: boolean;
    records: any;
    paginator: any;
  }>) &
    IAction;
  getCityByCoords: ((lat, lng) => Promise<Resource[]>) & IAction;
  filter: ((filter) => Promise<Resource[]>) & IAction;
  getCitiesClusters: ((filters?) => Promise<Resource[]>) & IAction;
  getBloodStationsByBounds: ((
    boxes: number[][],
    filters?
  ) => Promise<Resource[]>) &
    IAction;
  remove: ((record) => void) & IAction;
  save: ((data) => Promise<AxiosResponse<any>>) & IAction;
  deleteDonation: ((id) => Promise<AxiosResponse<any>>) & IAction;
  edit: ((data) => Promise<AxiosResponse<any>>) & IAction;
  saveFeedBack: ((data) => Promise<AxiosResponse<any>>) & IAction;
  loadOne: ((id, filters?) => Promise<Resource>) & IAction;
  changeDonationDate: ((date) => Promise<{ exists: boolean } | { error: string }>) & IAction;
  loadLast: ((
    page_size?,
    ordering?
  ) => Promise<{
    lastBloodType: string
  }>) &
    IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.load = action((page_size: number = 25, page = 1, filters = {}) => {
      const params = { page_size: page_size, page: page, ...filters };
      return this.client.get(this.url, { params: params }).then((response) => {
        const paginator = {
          count: response.data.count,
          num_pages: response.data.num_pages,
          next: response.data.next,
          prev: response.data.previous,
        };

        const has_user_city = response.data?.has_user_city;

        const records = response.data.results.map(
          (record) => new Resource({ record, client: this.client, store: this })
        );
        runInAction(() => {
          records.forEach(storeRecord(this.records));
        });
        return {
          records: records,
          paginator: paginator,
          has_user_city: has_user_city,
        };
      });
    });

    this.loadLast = action((page_size: number = 1, ordering = '-created_at') => {
      const params = { page_size: page_size, ordering: ordering }
      return this.client.get(this.url, {params: params}).then((response) => {
        const lastDonType = response?.data?.results[0]?.blood_class

        return {
          lastBloodType: lastDonType
        }
      })
    })

    this.getCityByCoords = action((lat, lng) => {
      return this.client
        .get(`${this.url}by_location/?lat=${lat}&lng=${lng}`)
        .then((response) => {
          return response.data;
        });
    });

    this.filter = action((filter) => {
      const queryString = filterQueryString(filter);
      return this.client.get(`${this.url}?${queryString}`).then((response) => {
        return response.data.results.map(
          (record) => new Resource({ record, client: this.client, store: this })
        );
      });
    });

    this.getCitiesClusters = action((filters?) => {
      const queryString = filterQueryString(filters);
      return this.client
        .get(`${this.url}map/?${queryString}`)
        .then((response) => {
          return response.data;
        });
    });

    this.getBloodStationsByBounds = action((boxes: number[][], filters?) => {
      const queryString = filterQueryString({ ...filters });
      return this.client
        .get(
          `${this.url}map/?bbox1=${boxes[0][0]},${boxes[0][1]}&bbox2=${boxes[1][0]},${boxes[1][1]}&${queryString}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    });

    this.remove = action((record) => {
      this.records.replace(this.records.filter((r) => r.id !== record.id));
    });

    this.save = action((data) => {
      return this.client.post(this.url, data);
    });

    this.edit = action((data) => {
      return this.client.patch(this.url + data.id + "/", data);
    });

    this.loadOne = action((id, filters?) => {
      if (id === "undefined") {
        id = 1;
      }
      const queryString = filterQueryString(filters);
      return this.client
        .get(this.url + `${id}/?${queryString}`)
        .then((response) => {
          return response?.data;
        });
    });

    this.saveFeedBack = action((data) => {
      if (data.id) {
        return this.client.patch(`/api/donation_feedback/${data.id}/`, data);
      } else {
        return this.client.post(`/api/donation_feedback/`, data);
      }
    });

    this.deleteDonation = action((id) => {
      return this.client.delete(this.url + `${id}/`);
    });

    this.changeDonationDate = action((date) => {
      return this.client
        .get(`/api/donations/is-exists/?donate_at=${date}`)
        .then((response) => {
          return response?.data;
        });
    });
  }

  all() {
    return this.records;
  }
}
