import DefaultFormModel from "../../HelpMePage/models/defaultModel";
import { observable } from "mobx";
import { TCity } from "../../HelpMePage/models/RequirementStep";

const FILTERS_MODEL = {
  by_year: {
    required: true,
    value: false,
  },
  country: {
    required: true,
    value: null,
    placeholder: "Не выбрано",
  },
  region: {
    required: true,
    value: null,
    placeholder: "Не выбрано",
  },
  city: {
    required: true,
    value: null,
    placeholder: "Не выбрано",
  },
  blood_station_id: {
    required: true,
    value: null,
    placeholder: "Не выбрано",
  },
  blood_group: {
    required: true,
    value: [],
  },
  kell: {
    required: true,
    value: "any",
  },
  age_start: {
    required: true,
    value: "",
    placeholder: "от",
  },
  age_end: {
    required: true,
    value: "",
    placeholder: "до",
  },
  gender: {
    required: true,
    value: "any",
  },
  organization_id: {
    required: true,
    value: null,
    placeholder: "Не выбрано",
  },
  components: {
    required: true,
    value: [],
  },
};

// /api/users_top/
// за год - by_year=true, по дефолту за все время
// Страна - country=айди
// Регион - region=айди
// Город - city=айди
// Центр крови - blood_station_id=айди
// Группы крови - blood_group=o_plus,o_minus,a_plus,a_minus,b_plus,b_minus,ab_plus,ab_minus
// kell-фактор - kell=positive, negative
// возраст от - age_start=0
// возраст до - age_end=150
// Пол - gender=male или female
// организация - organization_id=айди
// компоненты - components=blood, plasma, platelets, erythrocytes, leukocytes

export type TFiltersModel = {
  by_year: boolean;
  country: TCity;
  region: TCity;
  city: TCity;
  blood_station_id: TCity;
  blood_group: string | string[];
  kell: string;
  age_start: string;
  age_end: string;
  gender: string;
  organization_id: TCity;
  components: string | string[];
};

class FiltersModel extends DefaultFormModel<typeof FILTERS_MODEL> {
  @observable by_year: boolean;
  @observable country: TCity;
  @observable region: TCity;
  @observable city: TCity;
  @observable blood_station_id: TCity;
  @observable blood_group: string[];
  @observable kell: string;
  @observable age_start: string;
  @observable age_end: string;
  @observable gender: string;
  @observable organization_id: TCity;
  @observable components: string[];

  constructor(initialData?: TFiltersModel) {
    super(FILTERS_MODEL);
    this.Instantiate(FILTERS_MODEL);

    const getByYear = (value) => {
      switch (typeof value) {
        case "string":
          return value === "true";
        case "boolean":
          return value;
        default:
          return false;
      }
    };

    const parseStringToArray = (value: string | string[]) => {
      if (typeof value === "string") return value.split(",");
      else return value;
    };

    this.kell = initialData?.kell || "any";
    this.gender = initialData?.gender || "any";
    this.by_year =
      getByYear(initialData?.by_year) || FILTERS_MODEL.by_year.value;
    this.country = initialData?.country || null;
    this.region = initialData?.region || null;
    this.city = initialData?.city || null;
    this.blood_station_id = initialData?.blood_station_id || null;
    this.blood_group = initialData?.blood_group
      ? parseStringToArray(initialData.blood_group)
      : FILTERS_MODEL.blood_group.value;
    this.age_start = initialData?.age_start || FILTERS_MODEL.age_start.value;
    this.age_end = initialData?.age_end || FILTERS_MODEL.age_end.value;
    this.organization_id = initialData?.organization_id || null;
    this.components = initialData?.components
      ? parseStringToArray(initialData.components)
      : FILTERS_MODEL.components.value;
  }
}

export default FiltersModel;
