import {AxiosInstance, AxiosResponse} from "axios";
import {action, IAction, runInAction} from "mobx";

export default class AdditionalPlacesStore {
  url: string;
  client: AxiosInstance;
  load: ((id) => Promise<AxiosResponse<any>>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;

    this.load = action((id) => {
      return this.client
        .get(this.url + `?city_id=${id}`)
        .then(response => {
          return response.data;
        })
    });

  }

}
