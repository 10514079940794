import {AxiosInstance} from "axios";
import {action, IAction} from "mobx";
import Resource from "./Resource";

export default class RandomActivities {
  url: string;
  client: AxiosInstance;
  getRandomCards: (() => Promise<Resource[]>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;
    this.getRandomCards = action(() => {
      return this.client
        .get(this.url)
        .then(response => {
          return response.data;
        })
    });
  }
}