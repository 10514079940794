import {action, IAction, runInAction} from "mobx";
import {AxiosResponse, AxiosStatic} from "axios";
import ResourceStore from "./ResourseStore";
import {City} from "../entities";

export default class Resource {
  id: number;
  store: ResourceStore;
  client: AxiosStatic;
  data: Object;
  save: (() => Promise<AxiosResponse<any>>) & IAction;
  update: (() => Promise<AxiosResponse<any>>) & IAction;
  delete: (() => Promise<AxiosResponse<any>>) & IAction;
  lat: any;
  lng: any;
  slug?: number | string;
  title: string;
  state: string;
  blood_group: string;
  kell: string;
  cant_donate: boolean;
  address?: string;
  city?: City;

  constructor({record, client, store}) {
    this.client = client;
    this.store = store;
    this.id = record.id;
    this.data = record;

    this.save = action(() => {
      return this.client.post(this.store.url)
    });

    this.update = action(() => {
      return this.client
        .patch(`${this.store.url}${this.id}/`)
        .then(response => {
          runInAction(() => {
            Object.assign(this.data, response.data);
          });
          return response;
        });
    });

    this.delete = action(() => {
      return this.client.delete(`${this.store.url}${this.id}/`).then(response => {
        runInAction(() => {
          this.store.remove(this);
        });
        return response;
      });
    });
  }
}
