import { action, computed, observable, runInAction, toJS } from "mobx";
import { AxiosInstance } from "axios";
import FiltersModel, {
  TFiltersModel,
} from "../components/topDonors/models/FiltersModel";
import { TUserData } from "./EventStore";
import { TCity } from "../components/HelpMePage/models/RequirementStep";
import PlaceStore from "./PlaceStore";

type TTopUserData = {
  place: "no_place" | number;
};

class TopDonorPageStore {
  @observable private _url: string;
  @observable private _client: AxiosInstance;
  @observable private _model: FiltersModel;
  @observable private _pseudoModel: FiltersModel;
  @observable private _isMainLoading: boolean;
  @observable private _isContentLoading: boolean;
  @observable private _filteredUsers: TUserData[];
  @observable private _userData: TTopUserData;
  @observable private _placeStore: PlaceStore;
  @observable modalOpen = false;
  @observable shareModalOpen = false;

  constructor(url: string, client: AxiosInstance, placeStore: PlaceStore) {
    this._url = url;
    this._client = client;
    this._placeStore = placeStore;
    this._isMainLoading = true;
    this._isContentLoading = true;
    this._model = new FiltersModel();
    this._pseudoModel = new FiltersModel();
    this._filteredUsers = [];
  }

  @computed get model() {
    return this._model;
  }

  @computed get pseudoModel() {
    return this._pseudoModel;
  }

  @computed get isMainLoading() {
    return this._isMainLoading;
  }

  @computed get isContentLoading() {
    return this._isContentLoading;
  }

  @computed get currentModelInputData() {
    return this.pseudoModel.inputData;
  }

  @computed get users() {
    return this._filteredUsers;
  }

  @computed get isYearSort() {
    return this.model.by_year;
  }

  @computed get appliedFiltersCount() {
    let count = this.pseudoModel.requiredFields
      .map((field) => {
        return (
          JSON.stringify(this.pseudoModel[field]) !=
          JSON.stringify(this.pseudoModel.inputData[field].value)
        );
      })
      .filter(Boolean).length;

    if (this.pseudoModel.age_start.length && this.pseudoModel.age_end.length) {
      count--;
    }

    if (this.pseudoModel.by_year) {
      count--;
    }

    return count;
  }

  @computed get placeStore() {
    return this._placeStore;
  }

  @computed get userData() {
    return this._userData;
  }

  getValueFromInputDataKey = (key: keyof FiltersModel) => {
    if (this.model[key])
      if (typeof this.model[key] === "object") {
        if (Array.isArray(this.model[key])) {
          if ((this.model[key] as [])?.length)
            return `${key}=${(this.model[key] as []).join(",")}`;
        }
        if ((this.model[key] as TCity)?.value)
          return `${key}=${(this.model[key] as TCity).value}`;
      } else {
        if (this.model[key] !== "any") return `${key}=${this.model[key]}`;
      }
  };

  getValueFromInputDataField = (key: keyof FiltersModel) => {
    if (this.model[key])
      if (typeof this.model[key] === "object") {
        if (Array.isArray(this.model[key])) {
          if ((this.model[key] as [])?.length)
            return { [key]: (this.model[key] as []).join(",") };
        }
        if ((this.model[key] as TCity)?.value)
          return { [key]: (this.model[key] as TCity).value };
      } else {
        if (this.model[key] !== "any") return { [key]: this.model[key] };
      }
  };

  @computed get sortQueryString() {
    const inputList = Object.keys(this.model?.inputData);
    const test = inputList
      .map((input: keyof FiltersModel) => this.getValueFromInputDataKey(input))
      .filter(Boolean);
    if (test.length) {
      return `?${test.join("&")}`;
    }
    return undefined;
  }

  @action getSortFilterObject() {
    const inputList = Object.keys(this.model.inputData);
    const array = inputList
      .map((input: keyof FiltersModel) =>
        this.getValueFromInputDataField(input)
      )
      .filter(Boolean);
    return Object.assign({}, ...array);
  }

  @action setModel = (v: TFiltersModel) => (this._model = new FiltersModel(v));
  @action setOpenModal = (v: boolean) => (this.modalOpen = v);
  @action setShareOpenModal = (v: boolean) => (this.shareModalOpen = v);
  @action setPseudoModel = (v: TFiltersModel) =>
    (this._pseudoModel = new FiltersModel(v));
  @action setUserData = (v) => (this._userData = v);

  @action getData = () => {
    this.setContentLoading(true);
    return this._client
      .get(this._url, {
        params: this.getSortFilterObject(),
      })
      .then((res) => {
        runInAction(() => {
          this.setFilteredUsers(res.data.items);
          this.setUserData(res.data.user_info);
        });
      })
      .finally(() => {
        this.setContentLoading(false);
      });
  };

  @action initStore = async () => {
    this.setMainLoading(true);
    await this.getData();
    this.setMainLoading(false);
  };

  @action private setFilteredUsers = (v: TUserData[]) =>
    (this._filteredUsers = v);
  @action private setMainLoading = (v: boolean) => (this._isMainLoading = v);
  @action private setContentLoading = (v: boolean) =>
    (this._isContentLoading = v);

  @action onChange = <TProp extends keyof FiltersModel>(
    prop: TProp,
    value?: FiltersModel[TProp]
  ) => {
    this.pseudoModel.setValueByProperty(prop, value);
  };

  @action onChangeMain = <TProp extends keyof FiltersModel>(
    prop: TProp,
    value?: FiltersModel[TProp]
  ) => {
    this.model.setValueByProperty(prop, value);
  };

  @action onChangeMultiple = <TProp extends keyof FiltersModel>(
    prop: TProp,
    value: FiltersModel[TProp]
  ) => {
    this.pseudoModel.setValueByPropertyMultiple(prop, toJS(value));
  };

  @action setFilters = async () => {
    this.setModel({ ...this.pseudoModel, by_year: this.model.by_year });
    await this.getData();
  };

  @action resetFilters = async () => {
    this.setModel(undefined);
    this.setPseudoModel(undefined);
    this.placeStore.resetStore();
    await this.getData();
  };

  @action initWithInitialData = async (initialData: TFiltersModel) => {
    this.placeStore.resetStore();
    this._pseudoModel = new FiltersModel(initialData);
    this._model = new FiltersModel(initialData);
    await this.getData();
  };
}

export default TopDonorPageStore;
