import {AxiosInstance} from "axios";
import {action, IAction} from "mobx";
import Resource from "./Resource";

export default class PictureStore {
  url: string;
  client: AxiosInstance;
  upload: ((data) => Promise<Resource>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;

    this.upload = action((data) => {
      return this.client
        .post(this.url, data)
    })
  }

}
