import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, IAction} from "mobx";

export default class DonationFeedBackStore extends ResourseStore {
  url: string;
  client: AxiosInstance;
  setViewed: ((id: number) => Promise<any>) & IAction;

  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;

    this.setViewed = action((id: number) => {
      return this.client.post(`${this.url}${id}/view/`)
    })
  }
}
