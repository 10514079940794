import {AxiosInstance, AxiosResponse} from "axios";
import {action, IAction} from "mobx";

export default class OauthStore {
  url: string;
  client: AxiosInstance;
  authorize: ((data) => Promise<AxiosResponse<any>>) & IAction;

  constructor(client: AxiosInstance) {
    this.url = '/api/oauth/';
    this.client = client;

    this.authorize = action((data) => {
      data['redirect_uri'] = decodeURIComponent(data['redirect_uri'])
      return this.client
        .post(this.url, data)
        .then(response => {
          return response.data;
        })
    });

  }
}
