import Cookie from "mobx-cookie";
import { action, extendObservable, observable } from "mobx";
import removeCookie from "../hooks/removeCookie";

class TokenStore {
  token: Cookie;
  @observable server_token: string;

  constructor(initialData) {
    extendObservable(this, {
      token: new Cookie("token"),
    });
    this.server_token = initialData?.server_token || null;
  }

  @action get() {
    return this.server_token || this.token.value;
  }

  @action set(token: string, server = false) {
    if (server) {
      this.server_token = token;
    } else {
      this.token.set(token, { expires: 365, domain: '.donorsearch.org' });
    }
  }

  @action remove() {
    removeCookie("token", ".donorsearch.org");
    removeCookie("City_id", "unity.redcross.ru");
    this.token.remove();
    this.server_token = null;
  }
}

export default TokenStore;
