import {AxiosInstance} from "axios";
import {action, IAction, observable, runInAction} from "mobx";

const storeRecord = (records, newRecord) => {
  const existingRecord = records.find(r => r.id === newRecord.id);
  if (existingRecord) {
    Object.assign(existingRecord, newRecord);
    return existingRecord;
  } else {
    records.push(newRecord);
    return newRecord;
  }
};

export default class StoriesStore {
  url: string;
  client: AxiosInstance;
  loadStories: (() => Promise<Object>) & IAction;
  loadNextStories: (() => Promise<Object>) & IAction;
  viewStory: ((id) => Promise<Object>) & IAction;
  likeStory: ((id) => Promise<Object>) & IAction;
  dislikeStory: ((id) => Promise<Object>) & IAction;
  update: (() => Promise<Object>) & IAction;
  @observable stories: any[] = null
  @observable data: any = {}
  @observable countStories: number = 0

  constructor(url: string, client: AxiosInstance) {
    this.url = url;
    this.client = client;

    this.loadStories = action(() => {
      return this.client
        .get(this.url, {params: {'page_size': 10, 'page': 1}})
        .then(response => {
          this.setStories(response.data.results)
          this.setData(response.data)
          return this.stories;
        })
    });

    this.loadNextStories = action(() => {
      return this.client
        .get(this.data.next)
        .then(response => {
          this.setData(response.data)
          runInAction(() => {
            response.data.results?.map(res => {
              this.stories.push(res)
            })
          })
          return this.stories
        })
    })

    this.likeStory = action((id) => {
      return this.client.post(`${this.url}${id}/like/`).then(response => {
        runInAction(() => {
          storeRecord(this.stories, response.data);
        });
        return response.data
      })
    })

    this.dislikeStory = action((id) => {
      return this.client.post(`${this.url}${id}/dislike/`).then(response => {
        runInAction(() => {
          storeRecord(this.stories, response.data);
        });
        return response.data
      })
    })

    this.update = action(() => {
      return this.client
        .get(this.url)
        .then(response => {
          runInAction(() => {
            Object.assign(this.stories, response.data.results);
          });
          return response.data.results;
        });
    });

    this.viewStory = action((id) => {
      return this.client.post(`${this.url}${id}/view/`).then(response => {
        runInAction(() => {
          storeRecord(this.stories, response.data);
        });
        return response.data
      })
    })

  }

  @action setStories(stories: any) {
    this.stories = stories
  }

  @action setData(data: object) {
    this.data = data
  }

}
