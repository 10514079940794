import { AxiosInstance, AxiosResponse } from "axios";
import { action, computed, observable, toJS } from "mobx";
import { User } from "../entities";

type TDonatorAdvices = {
  id: number;
  user: User;
  text: string;
  created_at: string;
};

type TDonatorAdvicesResponse = {
  can_add: boolean;
  can_submit_form: boolean;
  count: number;
  next: null;
  num_pages: number;
  previous: null;
  results: TDonatorAdvices[];
};

type TModalData = {
  can_add: boolean;
  can_submit_form: boolean;
  text: string;
  is_open: boolean;
};

export default class DonatorAdvicesStore {
  @observable _url: string;
  @observable _client: AxiosInstance;

  @observable _donatorAdvices: TDonatorAdvices[];
  @observable _loading: boolean;
  @observable _donatorModalData;

  @observable _isDone: boolean;

  @observable afterRedirect: boolean;

  constructor(url: string, client: AxiosInstance) {
    this._url = url;
    this._client = client;
  }

  @action setAfterRedirect = (value: boolean) => (this.afterRedirect = value);

  @action setDonatorAdvices = (value: TDonatorAdvices[]) =>
    (this._donatorAdvices = value);

  @action setDonatorModalData = (value: TModalData) =>
    (this._donatorModalData = value);

  @action load = () => {
    return this._client
      .get(this._url)
      .then((r: AxiosResponse<TDonatorAdvicesResponse>) => {
        this.setDonatorAdvices(r.data.results);
        this.setDonatorModalData({
          can_add: r.data.can_add,
          text: "",
          can_submit_form: r.data.can_submit_form,
          is_open: false,
        });
      });
  };

  @action setDonatorAdviceModalByKey = (
    key: keyof TModalData,
    value: TModalData[keyof TModalData]
  ) => (this._donatorModalData[key] = value);

  @action toggleDonatorAdviceModal = (value: boolean) =>
    this.setDonatorAdviceModalByKey("is_open", value);

  @action setDonatorModalDataText = (value: string) =>
    this.setDonatorAdviceModalByKey("text", value);

  @action setLoading = (value: boolean) => (this._loading = value);

  @action setIsDone = (value: boolean) => (this._isDone = value);

  @action sendDonatorAdvice = () => {
    if (
      !this.donatorModalData?.can_submit_form ||
      this.donatorModalData?.text?.length <= 0
    )
      return false;
    this.setLoading(true);
    return this._client
      .post(this._url, {
        text: this.donatorModalData.text,
      })
      .then(() => {
        this.setLoading(false);
        this.setIsDone(true);
      })
      .catch((err) => {
        this.setLoading(false);
      });
  };

  @computed get donatorAdvices() {
    return toJS(this._donatorAdvices);
  }

  @computed get donatorModalData() {
    return toJS(this._donatorModalData);
  }

  @computed get buttonStatus() {
    if (this.loading) return false;
    if (!this.donatorModalData?.can_submit_form) return false;
    return this.donatorModalData?.text?.length > 0;
  }

  @computed get loading() {
    return toJS(this._loading);
  }

  @computed get isDone() {
    return toJS(this._isDone);
  }
}
