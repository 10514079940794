import ResourseStore from "./ResourseStore";
import {AxiosInstance} from "axios";
import {action, observable} from "mobx";

export default class FundraisingStore extends ResourseStore {
  url: string;
  client: AxiosInstance;

  @observable paymentsCount: number;

  addCollect: ((data: any) => any);
  getCollect: ((id: string) => any);
  editCollect: ((data: any, id: string) => any);
  getProfileCollect: (() => any);
  getPayments: (() => any);
  getDonatePayments: (() => any);
  
  constructor(url: string, client: AxiosInstance) {
    super(url, client);
    this.url = url;
    this.client = client;
    this.records = observable([]);

    this.addCollect = action((data: any) => {
      return this.client.post(`/api/collects/`, data).then(resp => resp.data)
    })

    this.getCollect = action((id: string) => {
      return this.client.get(`/api/collects/${id}/`,).then(resp => resp.data)
    })

    this.editCollect = action((data: any, id: string) => {
      return this.client.patch(`/api/collects/${id}/`, data).then(resp => resp.data)
    })

    this.getProfileCollect = action(() => {
      return this.client.get(`/api/profile/collects/`,).then(resp => resp.data)
    })

    this.getPayments = action(() => {
      return this.client.get(`/api/profile/payments/`).then(resp => resp.data)
    })

    this.getDonatePayments = action((page = 1) => {
      return this.client.get(`/api/payments/?ordering=-created_at&page_size=50&page=${page}`).then(resp => resp.data)
    })
  }

  @action setPaymentsCount(value: number) {
    this.paymentsCount = value;
  }
}
