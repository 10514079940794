import { action, computed, observable } from "mobx";

interface WindowDimensions {
  width: number;
  height: number;
}

class UiStore {
  @observable title: string = "DonorSearch";
  @observable socialError: string = "";
  @observable activeTab: number = 0;
  @observable windowDimensions: WindowDimensions = {
    width: 1920,
    height: 1080,
  };
  @observable needSendSendsay: boolean = false;
  @observable notificationCount: number | null = null;

  constructor(initialData) {
    this.socialError = initialData?.socialError || "";
    this.title = initialData?.title || "DonorSearch";

    if (typeof window !== "undefined") {
      this.updateWindowDimensions();
      window.addEventListener("resize", () => {
        this.updateWindowDimensions();
      });
      window.onresize = (event) => {
        this.updateWindowDimensions();
      };
    }
  }

  @computed get isMobile() {
    return this.windowDimensions.width < 767;
  }

  @computed get deviceType() {
    switch (true) {
      case this.windowDimensions.width < 768:
        return "Mobile";
      case this.windowDimensions.width >= 768 &&
        this.windowDimensions.width < 1200:
        return "Tablet";
      case this.windowDimensions.width >= 1200 &&
        this.windowDimensions.width < 1440:
        return "Laptop";
      case this.windowDimensions.width >= 1440 &&
        this.windowDimensions.width < 1920:
        return "Desktop";
      case this.windowDimensions.width >= 1920:
        return "Large";
    }
  }

  @computed get getNotificationCount() {
    return this.notificationCount;
  }

  @action setTitle(title: string) {
    this.title = title;
  }

  @action setNeedSendSendsay(value: boolean) {
    this.needSendSendsay = value;
  }

  @action updateWindowDimensions() {
    this.windowDimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  @action setSocialError(error: string) {
    this.socialError = error;
  }

  @action setActiveTab(activeTab: number) {
    this.activeTab = activeTab;
  }

  @action setNotificationCount(count: number) {
    this.notificationCount = count;
  }
}

export default UiStore;
