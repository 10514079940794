import { AxiosInstance } from "axios";
import { action, computed, observable, toJS } from "mobx";
import RequirementStep from "../components/HelpMePage/models/RequirementStep";
import RecipientDataStep from "../components/HelpMePage/models/RecipientDataStep";
import ResourceStore from "./ResourseStore";
import { get_city_label, isEmpty } from "../utils/misc";
import BloodStationStore from "./BloodStationStore";
import redirectTo from "../utils/redirectTo";

type TTrustedModels = RecipientDataStep & RequirementStep;

type TCity = {
  value: number;
  label: string;
};

type TCityFilter = {
  s?: string;
};

class HelpMeStore {
  @observable _url: string;
  @observable _client: AxiosInstance;
  @observable initializedModels: (RequirementStep | RecipientDataStep)[];
  @observable isWelcomePage: boolean;
  @observable _cityOptions: TCity[];
  @observable _bloodStationOptions: TCity[];
  @observable cityOptionsFilter: TCityFilter;
  @observable citiesStore: ResourceStore;
  @observable bloodStationsStore: BloodStationStore;
  @observable isLoading: boolean;
  @observable firstError: string;

  constructor(
    url: string,
    client: AxiosInstance,
    citiesStore: ResourceStore,
    bloodStationsStore: BloodStationStore
  ) {
    this._url = url;
    this._client = client;
    this.initializedModels = [new RequirementStep(), new RecipientDataStep()];
    this.step_count = this.initializedModels.length;
    this.isWelcomePage = true;
    this._cityOptions = undefined;
    this.cityOptionsFilter = undefined;
    this.citiesStore = citiesStore;
    this.bloodStationsStore = bloodStationsStore;
    this.isLoading = false;
    this.firstError = undefined;
  }

  @observable step: number = 0;
  @observable step_count: number = 0;
  @observable error: string;

  @computed get currentModel() {
    return this.initializedModels[this.step];
  }

  @computed get currentModelErrors() {
    return toJS(this.currentModel.errorList);
  }

  @computed get currentModelInputData() {
    return this.currentModel.inputData;
  }

  @computed get allModelsData() {
    return toJS(
      Object.assign(
        {},
        ...this.initializedModels.map((model) => {
          return toJS(
            Object.assign(
              {},
              ...Object.keys(toJS(model.inputData)).map((id) => ({
                [id]: toJS(model[id]),
              }))
            )
          );
        })
      )
    );
  }

  @computed get cityOptionsFilterForRequest() {
    return this.cityOptionsFilter;
  }

  @computed get cityOptions() {
    return toJS(this._cityOptions);
  }

  @computed get bloodStationOptions() {
    return toJS(this._bloodStationOptions);
  }

  @computed get scrollError() {
    return this.firstError;
  }

  @action resetFirstError = () => {
    this.firstError = undefined;
  };

  @action reInitStore = () => {
    this.initializedModels = [new RequirementStep(), new RecipientDataStep()];
    this.step_count = this.initializedModels.length;
    this.step = 0;
    this.isLoading = false;
    this.isWelcomePage = true;
    this._cityOptions = undefined;
    this.firstError = undefined;
    this.cityOptionsFilter = undefined;
    this._bloodStationOptions = undefined;
  };

  @action setBloodStationOptions = (v: TCity[]) => {
    this._bloodStationOptions = v;
  };

  @action updateBloodStationOptions = (item: TCity) => {
    this.bloodStationsStore
      .filter({ city_id: item.value, closed: false })
      .then((records) => {
        this.setBloodStationOptions(
          records.map((record: any) => ({
            value: record.data?.id as number,
            label: record.data.title as string,
          }))
        );
      });
  };

  @action setCityOptions = (v: TCity[]) => {
    this._cityOptions = v;
  };

  @action updateCityOptions = async (v: string) => {
    this.changeCityOptionsFilter(v);
    return this.citiesStore
      .filter({ ...this.cityOptionsFilterForRequest, all_bs: true })
      .then((records) => {
        const cities = records.map((record: any) => ({
          value: record.data?.id as number,
          label: get_city_label(record.data) as string,
        }));
        this.setCityOptions(cities);
        return cities;
      });
  };

  @action changeCityOptionsFilter = (v: string) => {
    this.cityOptionsFilter = { s: v || "" };
  };

  @action closeWelcomePage = () => (this.isWelcomePage = false);
  @action openWelcomePage = () => (this.isWelcomePage = true);

  @action setErrorField = <TProp extends keyof TTrustedModels>(
    prop: TProp,
    value?: TTrustedModels["inputData"][TProp]["default_error"]
  ) => {
    this.currentModel.setError(
      prop,
      value ? value : this.currentModel.inputData[prop].default_error
    );
  };

  @action onChange = <TProp extends keyof TTrustedModels>(
    prop: TProp,
    value: TTrustedModels[TProp]
  ) => {
    this.currentModel.setValueByProperty(prop, value);
  };

  @action onChangeMultiple = <TProp extends keyof TTrustedModels>(
    prop: TProp,
    value: TTrustedModels[TProp]
  ) => {
    this.currentModel.setValueByPropertyMultiple(prop, toJS(value));
  };

  @action stepIncrease = () => {
    if (this.step_count > this.step) {
      this.step += 1;
    }
  };

  @action stepDecrease = () => {
    if (this.step > 0) {
      this.step -= 1;
    }
  };

  @action setStep = (step: number) => {
    this.step = step;
  };

  @action prevStep = () => {
    this.stepDecrease();
  };

  @action getFirstErrorFromErrorList() {
    if (!this.currentModelErrors) return undefined;
    if (!Object.keys(this.currentModelErrors)) return undefined;
    return Object.keys(this.currentModelErrors)[0];
  }

  @action nextStep = () => {
    this.firstError = undefined;
    this.currentModel.checkInputsState();
    if (!isEmpty(this.currentModelErrors)) {
      this.firstError = this.getFirstErrorFromErrorList();
      return;
    } else {
      this.stepIncrease();
    }
  };

  @action submitForm = () => {
    this.firstError = undefined;
    this.currentModel.checkInputsState();
    if (!isEmpty(this.currentModelErrors)) {
      this.firstError = this.getFirstErrorFromErrorList();
      return;
    }
    this.isLoading = true;

    const city_id = this.allModelsData.city.value;
    const bs_id = this.allModelsData.blood_station.value;
    const data = {
      ...this.allModelsData,
      blood_station: undefined,
      city: undefined,
      city_id: city_id,
      blood_station_id: bs_id,
    };

    const formData = new FormData();
    Object.keys(data).map((key) => {
      if (Array.isArray(data[key])) {
        data[key].map((item, index) => {
          if (item) formData.append(`${key}[${index}]`, item);
        });
      } else {
        if (data[key]) formData.append(key, data[key]);
      }
    });

    this._client
      .post(this._url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res?.data?.id) {
          redirectTo(`/recipient/${res.data.id}`);
        } else {
          console.error("ЧТО-ТО ПОШЛО НЕ ТАК");
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  };
}

export default HelpMeStore;
